import { t } from '@lingui/macro';
import gql from 'graphql-tag';
import { FC, useEffect, useState } from 'react';

import config from 'config/config';

import { client } from '../apolloConnector/apolloConnector';
import auth from '../auth/auth';
import './Chat.scss';
import { useEmbeddedSVC } from './ESW';
import { useChatVisibility } from './useChatVisibility';
import fixlyLogoImg from './fixly-logo-32.svg';

const {
  SALESFORCE_BASE_CORE_URL,
  SALESFORCE_COMMUNITY_ENDPOINT_URL,
  SALESFORCE_ORG_ID,
  SALESFORCE_BUTTON_ID,
  SALESFORCE_BASE_LIVE_AGENT_CONTENT_URL,
  SALESFORCE_BASE_LIVE_AGENT_URL,
  SALESFORCE_ESW_LIVE_AGENT_DEV_NAME,
} = config;

const userContactsQuery = gql`
  query UserContacts {
    me {
      id
      firstName
      lastName
      email
      phone
    }
  }
`;

type UserContactsResponse = {
  me: {
    id: string;
    firstName?: string;
    lastName?: string;
    email: string;
    phone?: string;
  };
};

const createOfflineButton = (): HTMLAnchorElement => {
  const a = document.createElement('a');
  // Styles are from uikit/Button
  a.setAttribute('class', 'button button_cta button_fullWidth');
  a.innerText = t`Formularz kontaktowy`;
  a.href = config.FIX_HELPDESK_CONTACT_URL;
  return a;
};

const createOfflineMessage = (): HTMLParagraphElement => {
  const p = document.createElement('p');
  // Styles are from uikit/Text
  p.setAttribute('class', 'textP1 offlineChatMessage');
  p.innerText = t`Czat jest obecnie niedostępny. Skontaktuj się z nami poprzez Formularz kontaktowy.`;
  return p;
};

const replaceChatBodyWithOfflineUI = (): void => {
  const chatContainer = document.querySelector('.dockableContainer');

  if (chatContainer) {
    const formContent = chatContainer.querySelector<HTMLDivElement>('.formContent');

    if (formContent) {
      formContent.innerHTML = '';
      formContent.appendChild(createOfflineMessage());
    }

    const buttonWrapper = chatContainer.querySelector<HTMLDivElement>('.buttonWrapper');
    if (buttonWrapper) {
      buttonWrapper.innerHTML = '';
      buttonWrapper.appendChild(createOfflineButton());
    }
  }
};

export const Chat: FC = () => {
  const { SVC } = useEmbeddedSVC();
  const [initialized, setInitialized] = useState<boolean>(false);

  const isVisible = useChatVisibility();

  useEffect(() => {
    const getFullName = (firstName = '', lastName = ''): string => {
      return `${firstName} ${lastName}`.trim();
    };

    if (!isVisible || !SVC) return;

    SVC.settings = {
      ...SVC.settings,
      displayHelpButton: true,
      language: config.LOCALE,
      defaultMinimizedText: t`Porozmawiaj z ekspertem`,
      loadingText: t`Ładowanie...`,
      offlineSupportMinimizedText: t`Skontaktuj się z nami`,
      smallCompanyLogoImgURL: fixlyLogoImg,
      enabledFeatures: ['LiveAgent'],
      entryFeature: 'LiveAgent',
    };

    if (!initialized) {
      SVC.addEventHandler('afterInit', () => {
        setInitialized(true);
      });

      /**
       * Expected Behaviour: The users are given a message that no agents are available
       * and given a hyperlink to create contact form cases.
       * This approach works but blinking
       */

      SVC.addEventHandler('afterMaximize', () => {
        SVC.addEventHandler('onAvailability', (event) => {
          if (event) {
            if (!event.isAgentAvailable) replaceChatBodyWithOfflineUI();
          }
        });
      });

      const initSVC = () =>
        SVC.init(
          SALESFORCE_BASE_CORE_URL,
          SALESFORCE_COMMUNITY_ENDPOINT_URL,
          'https://service.force.com',
          SALESFORCE_ORG_ID,
          'CS_Fixly_PL_Chat',
          {
            baseLiveAgentContentURL: SALESFORCE_BASE_LIVE_AGENT_CONTENT_URL,
            deploymentId: '5723X000000TxFV',
            buttonId: SALESFORCE_BUTTON_ID,
            baseLiveAgentURL: SALESFORCE_BASE_LIVE_AGENT_URL,
            eswLiveAgentDevName: SALESFORCE_ESW_LIVE_AGENT_DEV_NAME,
            isOfflineSupportEnabled: true,
          }
        );

      const initSVCPrefilled = async () => {
        const { data } = await client.query<UserContactsResponse>({
          query: userContactsQuery,
        });
        const { me } = data;
        const fullName = getFullName(me.firstName, me.lastName);

        SVC.settings.prepopulatedPrechatFields = {
          Subject: '',
          Name__c: fullName,
          SuppliedEmail: me.email || `${me.id}+noreply@fixly.pl`,
          Phone_number__c: me.phone,
        };
        initSVC();
      };

      const anonymous = !auth.isLoggedIn();

      if (anonymous) {
        initSVC();
      } else {
        initSVCPrefilled();
      }
    }
  }, [SVC, initialized, isVisible]);

  return null;
};
