import { useQuery } from '@apollo/react-hooks';
import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FC, useState } from 'react';

import { IosButton, AndroidButton } from 'uikit/Button';
import { TextP3 } from 'uikit/Text';
import analytics from 'utils/analytics/analytics';

import {
  hasBeenClosedRecently,
  profileCompletionQuery,
  ProviderProfileCompletion,
  PlacementType,
  setBannerAsSeen,
} from './InstallAppBanner.helpers';
import './InstallAppBanner.scss';

type InstallAppBannerProps = {
  type: PlacementType;
  className?: string;
  touchPoint?: string;
};

const InstallAppBanner: FC<InstallAppBannerProps> = (props) => {
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const { data, loading } = useQuery<{ me: { profileCompletion: ProviderProfileCompletion } }>(profileCompletionQuery, {
    skip: hasBeenClosedRecently(props.type),
  });

  const onClose = () => {
    setIsVisible(false);
    setBannerAsSeen(props.type);
  };

  if (!isVisible || hasBeenClosedRecently(props.type) || loading || data?.me.profileCompletion.installApp) return null;

  const { touchPoint } = props;
  const hostCN = classnames('installAppBanner', props.className);

  return (
    <div className={hostCN}>
      <button className="installAppBanner__close" type="button" title="Close" onClick={onClose}>
        <span className="installAppBanner__times" />
        <span className="visually-hidden">Close</span>
      </button>
      <div className="installAppBanner__body">
        <TextP3 className="installAppBanner__message">
          <Trans>
            <strong>Nie przegap żadnego zlecenia! Pobierz naszą aplikację mobilną</strong>, a powiadomienia dostaniesz
            prosto na telefon!
          </Trans>
        </TextP3>
        <div className="installAppBanner__buttons">
          <AndroidButton
            as="a"
            className="installAppBanner__button"
            href={`https://sp-app.fixly.pl/android-prom?touch_point_page=${touchPoint}`}
            target="_blank"
            rel="noopener"
            onClick={() => analytics.trackEvent('download_android_button_click', { touch_point_page: touchPoint })}
          >
            <Trans>Pobierz z Google Play</Trans>
          </AndroidButton>
          <IosButton
            as="a"
            className="installAppBanner__button"
            href={`https://sp-app.fixly.pl/ios-prom?touch_point_page=${touchPoint}`}
            target="_blank"
            rel="noopener"
            onClick={() => analytics.trackEvent('download_ios_button_click', { touch_point_page: touchPoint })}
          >
            <Trans>Pobierz z App Store</Trans>
          </IosButton>
        </div>
      </div>
    </div>
  );
};

export default InstallAppBanner;
