export const EU_COOKIE_KEY = 'EU_COOKIE_LAW_CONSENT';

export function isCookiesAccepted(): boolean {
  return document.cookie.indexOf(EU_COOKIE_KEY) !== -1;
}

export function setCookiesAccepted(): void {
  const expirationTime = new Date();
  expirationTime.setMonth(expirationTime.getMonth() + 1);
  document.cookie = `${EU_COOKIE_KEY}=true;expires=${expirationTime.toUTCString()};path=/`;
}
