import gql from 'graphql-tag';

import { isRoute } from '../helpers/helpers';

export type Header$User = {
  id: string;
  email: string;
  avatarUrl: string;
  firstName?: string;
  lastName?: string;
  slug: string;
  guest: boolean;
  isImpersonating: boolean;
  provider:
    | {
        id: string;
        onboardingStep: number;
      }
    | null
    | undefined;
};

export type Header$Props = {
  isProviderLayout?: boolean;
};

// @fixme Load current user on start
export const userDataQuery = gql`
  query CurrentUser {
    me {
      id
      email
      avatarUrl
      firstName
      lastName
      slug
      guest
      provider {
        id
        onboardingStep
      }
    }
    activeUserRequestsCount
  }
`;

const routesWithFixedHeader: string[] = ['front::index', 'front::categories.type', 'categories_seo'];

export const isDesktopFixedRoute = (url: string): boolean => {
  return routesWithFixedHeader.some((key) => isRoute(url, key));
};

const spRegistrationRouteKeys: string[] = [
  'front::provider.register.uk',
  'front::provider.register',
  'front::provider.register.city',
  'front::provider.register.l2',
  'front::provider.register.l2.requests',
  'front::provider.register.l3',
  'front::provider.register.l3.city',
];

export const isProviderRegisterRoute = (url: string): boolean => {
  return spRegistrationRouteKeys.some((key) => isRoute(url, key));
};
