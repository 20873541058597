import gql from 'graphql-tag';
import { client } from '../apolloConnector/apolloConnector';
import { captureException } from '../errorLogger/sentry';
import { isDocument, isWindow } from '../../common/helpers/helpers';
import { logDevOnly, createLogger } from '../logger/logger';
import { ExecutionResult } from 'apollo-link';
import { FirebaseOptions } from '@firebase/app-types';

export type TLocalFCMData = {
  token: string;
  uid: string;
};

export type TFCMNotificationPayload = {
  from: string;
  priority: string;
  notification?: Partial<NotificationOptions> & { title: string };
  fcmOptions?: {
    link: string;
  };
};

// HELPERS

export const log = createLogger(logDevOnly, 'FCMManager');

const FCM_SERVICE_WORKER_URL = '/fcm-sw.js';

export function getFirebaseConfig(): FirebaseOptions {
  if (typeof window !== 'undefined' && !('firebaseConfig' in window)) {
    throw new Error('No firebase config provided');
  }

  // @ts-ignore
  return window.firebaseConfig as FirebaseOptions;
}

export function getFCMPublicKey(): string {
  if (typeof window !== 'undefined' && !('FCMPublicKey' in window)) {
    throw new Error('No FCM public key provided');
  }

  // @ts-ignore
  return window.FCMPublicKey;
}

export function parseLocalFCMDataString(dataString?: string | null): TLocalFCMData | null {
  let data: TLocalFCMData | null = null;
  try {
    data = JSON.parse(dataString || '');
  } catch (e) {
    return null;
  }

  if (!data || !data.token || !data.uid) {
    return null;
  }

  return data;
}

// noinspection JSUnusedGlobalSymbols
export function parseNotificationPayload(payload: any): TFCMNotificationPayload | null {
  if (!payload) {
    return null;
  }

  const result: TFCMNotificationPayload = {
    from: typeof payload.from === 'string' ? payload.from : '',
    priority: typeof payload.priority === 'string' ? payload.priority : '',
  };

  if (payload.notification) {
    result.notification = {
      title: typeof payload.notification.title === 'string' ? payload.notification.title : '',
      ...payload.notification,
    };
  }

  if (payload.fcmOptions) {
    result.fcmOptions = {
      link: typeof payload.fcmOptions.link === 'string' ? payload.fcmOptions.link : '',
      ...payload.fcmOptions,
    };
  }

  return result;
}

const tokenRegistrationMutation = gql`
  mutation RegisterFCMToken($token: String!) {
    pushTokenUpdate(token: $token)
  }
`;

export async function registerTokenUtil(token: string): Promise<boolean> {
  try {
    const tokenRegistrationResponse: ExecutionResult<{ pushTokenUpdate: boolean }> = await client.mutate({
      mutation: tokenRegistrationMutation,
      variables: { token },
    });

    return (tokenRegistrationResponse.data && tokenRegistrationResponse.data.pushTokenUpdate) || false;
  } catch (e) {
    // @ts-expect-error type mismatch
    captureException(e);
    return false;
  }
}

const tokenUnregistrationMutation = gql`
  mutation UnregisterFCMToken($token: String!) {
    pushTokenRemove(token: $token)
  }
`;

export async function unregisterTokenUtil(token: string): Promise<boolean> {
  try {
    const tokenUnregistrationResponse: ExecutionResult<{ pushTokenRemove: boolean }> = await client.mutate({
      mutation: tokenUnregistrationMutation,
      variables: { token },
    });

    return (tokenUnregistrationResponse.data && tokenUnregistrationResponse.data.pushTokenRemove) || false;
  } catch (e) {
    // @ts-expect-error type mismatch
    captureException(e);
    return false;
  }
}

async function loadScript(url: string): Promise<void> {
  return new Promise((res, rej) => {
    if (document.querySelector(`script[src="${url}"]`)) {
      res();
      return;
    }

    const js = document.createElement('script');
    // @ts-ignore
    js.onload = res;
    js.onerror = rej;
    // $FlowFixMe, just ignore that there is no head in document
    document.head.appendChild(js);
    js.src = url;
  });
}

export function loadFirebaseSDK(): Promise<boolean> {
  // eslint-disable-next-line
  return new Promise(async (resolve) => {
    await Promise.resolve();

    if (!isDocument) return false;

    const scriptUrls = [
      'https://www.gstatic.com/firebasejs/5.6.0/firebase-app.js',
      'https://www.gstatic.com/firebasejs/5.6.0/firebase-messaging.js',
    ];

    let loadedScriptCount = 0;

    const scriptLoaded = async () => {
      loadedScriptCount += 1;

      if (loadedScriptCount === scriptUrls.length) {
        // Hack to allow firebase messaging to initialize
        await new Promise((res) => setTimeout(res));
        resolve(true);
      }
    };

    /* eslint-disable no-await-in-loop, no-restricted-syntax */
    for (const url of scriptUrls) {
      await loadScript(url);
      scriptLoaded();
    }
    /* eslint-enable no-await-in-loop, no-restricted-syntax */
  });
}

const logRegisterWorker = (...args: any[]) => log('[SW Registration]', ...args);

/**
 * This function is taken straight from firebase-messaging file
 * and is intended to prevent error to be thrown in global scope during Messaging registration.
 * source: https://www.gstatic.com/firebasejs/5.6.0/firebase-messaging.js
 */
export function platformSupportsMessaging(): boolean {
  // eslint-disable-next-line
  return (
    navigator.cookieEnabled &&
    'serviceWorker' in navigator &&
    'PushManager' in window &&
    'Notification' in window &&
    'fetch' in window &&
    ServiceWorkerRegistration.prototype.hasOwnProperty('showNotification') &&
    PushSubscription.prototype.hasOwnProperty('getKey')
  );
}

export async function registerWorker(): Promise<ServiceWorkerRegistration> {
  if (!isWindow) {
    throw new Error('Tries to register service worker when there is no window object');
  }

  const { serviceWorker } = navigator;

  if (!serviceWorker) {
    throw new Error('ServiceWorker is not supported');
  }

  // Get SW registration for root scope
  const currentSWRegistration = await serviceWorker.getRegistration('/');

  if (!currentSWRegistration) {
    logRegisterWorker('Will register new SW straightaway');
    return serviceWorker.register(FCM_SERVICE_WORKER_URL);
  }

  if (typeof currentSWRegistration.update === 'function') {
    logRegisterWorker('Registration will use update method');

    // NOTE: This will update serviceWorker at least any 24 hours
    // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration/update
    await currentSWRegistration.update();
    return currentSWRegistration;
  }

  logRegisterWorker('Will unregister and register again');

  await currentSWRegistration.unregister();
  return serviceWorker.register(FCM_SERVICE_WORKER_URL);
}
