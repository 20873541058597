import { useEffect } from 'react';
import { OneTrustScripts } from './oneTrust';

export const useLoad3rdPartyScripts = () => {
  const load3rdPartyScripts = () => {
    return [OneTrustScripts()];
  };

  useEffect(() => {
    load3rdPartyScripts();
  }, []);
};
