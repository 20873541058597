import { useMutation, useQuery, QueryHookOptions } from '@apollo/react-hooks';
import { t } from '@lingui/macro';
import gql from 'graphql-tag';
import { useEffect } from 'react';

import { useCurrentUser } from 'Components/CurrentUser/CurrentUser';
import useSnackbar from 'uikit/Snackbar/useSnackbar';
import { AuthResult } from 'utils/api';
import auth from 'utils/auth/auth';
import routes from 'utils/routeTranslator';

export const links = [
  {
    slug: 'zlecenia-budowlane',
    title: 'Zlecenia Budowlane',
  },
  {
    slug: 'zlecenia-transport-krajowy',
    title: 'Zlecenia Transportowe',
  },
  {
    slug: 'remonty-budynkow',
    title: 'Zlecenia Remontowe',
  },
  {
    slug: 'zlecenia-spawanie',
    title: 'Zlecenia Spawalnicze',
  },
  {
    slug: 'zlecenia-na-busa',
    title: 'Zlecenia na Busa',
  },
  {
    slug: 'zlecenia-dla-grafikow',
    title: 'Zlecenia Graficzne',
  },
  {
    slug: 'zlece-malowanie',
    title: 'Zlecenia Malowanie',
  },
  {
    slug: 'zlecenia-dla-elektrykow',
    title: 'Zlecenia Elektryczne',
  },
  {
    slug: 'copywriting-zlecenia',
    title: 'Zlecenia Dla Copywriterów',
  },
  {
    slug: 'zlecenia-brukarskie',
    title: 'Zlecenia Brukarskie',
  },
  {
    slug: 'zlecenia-stolarskie',
    title: 'Zlecenia Stolarskie',
  },
  {
    slug: 'zlecenia-poligraficzne',
    title: 'Zlecenia Poligraficzne',
  },
  {
    slug: 'zlecenia-dla-prawnikow',
    title: 'Zlecenia dla prawników',
  },
  {
    slug: 'zlecenia-dla-fotografa',
    title: 'Zlecenia dla fotografa',
  },
  {
    slug: 'zlecenia-slusarskie',
    title: 'Zlecenia Ślusarskie',
  },
  {
    slug: 'zlece-wykonanie-strony-internetowej',
    title: 'Zlecenia na strony www',
  },
  {
    slug: 'zlece-szycie',
    title: 'Zlecenia na szycie',
  },
  {
    slug: 'zlecenia-dla-dekarzy',
    title: 'Zlecenia Dekarskie',
  },
  {
    slug: 'zlecenia-na-projekt-wnetrza',
    title: 'Zlecenia Projektowe',
  },
];

type UserAccountResponse = {
  userAccount: {
    id: string;
    canSwitchUser: boolean;
  };
};

const userAccountQuery = gql`
  query UserAccount {
    userAccount {
      id
      canSwitchUser
    }
  }
`;

const useAccount = (options?: QueryHookOptions) => {
  return useQuery<UserAccountResponse>(userAccountQuery, {
    ...options,
    ssr: false,
  });
};

export const useCanSwitchRole = (): boolean | undefined => {
  const { currentUser } = useCurrentUser();
  const { data } = useAccount({
    skip: !currentUser,
  });

  return data?.userAccount.canSwitchUser;
};

const switchRoleMutation = gql`
  mutation SwitchUser {
    switchUser {
      userId
      accessToken
      refreshToken
      expiresAt
    }
  }
`;

type SwitchUserResponse = {
  switchUser: AuthResult;
};

export const useSwitchRole = () => {
  const { showSnackbar } = useSnackbar();

  const [mutate, { data }] = useMutation<SwitchUserResponse>(switchRoleMutation, {
    onError: (error) => {
      const message =
        (error.graphQLErrors && error.graphQLErrors[0].message) || error.networkError?.message || error.message;
      showSnackbar({
        message,
        variant: 'error',
      });
    },
  });

  useEffect(() => {
    if (data?.switchUser) {
      auth.storeCredentials(data.switchUser);
      // Hard reload is made on purpose
      window.location.href = routes.get('front::dashboard');
    }
  }, [data?.switchUser]);

  return () => {
    showSnackbar({ message: t`Przełączanie konta...` });
    return mutate();
  };
};
