import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { QueryProps } from 'react-apollo';

import { TPaymentOption } from 'Components/PaymentOptions/PaymentOption.helpers';
import { TPointsPackage } from 'Components/PaymentOptions/PaymentOptions.helpers';
import { VasTypeEnum } from 'Components/WalletModalNew/WalletModal.helpers';

// TYPES

export type TPremium$Vas = {
  id: string;
  slug: string;
  imageUrl: string;
  type: VasTypeEnum.extraRadius | VasTypeEnum.priorityQuoting | VasTypeEnum.extraOffer | VasTypeEnum.unlimitedQuoting;
  name: string;
  price: number;
  discount: number;
  salePrice: number;
  promoDays: number;
  expirationDays: number;
  description: string;
  features: string[];
  paymentOptions: TPaymentOption[];
};

export type TPremium$UserVas = {
  isAvailable: boolean;
  isEnabled: boolean;
  enabledAt: string;
  expiresAt: string;
  isSoldOut: boolean;
  vas: TPremium$Vas;
};

export interface IProviderVasData extends QueryProps<{}> {
  vases: TPremium$UserVas[];
  /**
   * @deprecated
   */
  pointsPackages: TPointsPackage[];
}

export type PremiumOptionsResponse = {
  vases: TPremium$UserVas[];
  /**
   * @deprecated
   */
  pointsPackages: TPointsPackage[];
};

// HELPERS

export const vasesQueryFragment = gql`
  fragment ProviderVas on Vas {
    id
    slug
    type
    name
    shortName
    price
    discount
    salePrice
    promoDays
    description
    features
    imageUrl
    expirationDays
    paymentOptions {
      id
      slug
      name
      description
      imageUrl
      url
    }
    prices {
      oneTime {
        price
        discount
        salePrice
      }
      subscription {
        price
        discount
        salePrice
      }
    }
  }
`;

export const premiumOptionsQuery = gql`
  ${vasesQueryFragment}

  query PremiumOptions {
    vases {
      isAvailable
      isEnabled
      enabledAt
      expiresAt
      isSoldOut
      canBeProlonged
      canRenewSubscription
      isSubscriptionEnabled
      subscriptionCardNumberMasked
      vas {
        ...ProviderVas
      }
    }
    pointsPackages {
      id
      name
      slug
      price
      discount
      salePrice
      amount
      validityPeriod
      promoText
      type
      paymentOptions {
        id
        slug
        name
        description
        imageUrl
        url
      }
    }
  }
`;

type PointsBalanceResponse = {
  me: {
    id: string;
    provider?: {
      id: string;
      account?: {
        pointsBalance: number;
      };
    };
  };
};

const pointsBalanceQuery = gql`
  query PointsBalance {
    me {
      id
      provider {
        id
        account {
          pointsBalance
        }
      }
    }
  }
`;

export const usePointsBalance = (skip = false) => useQuery<PointsBalanceResponse>(pointsBalanceQuery, { skip });

export enum RequestLabelType {
  reviews = 'stars',
  extraRadius = 'vas_extra_radius',
  extraOffer = 'vas_extra_offer',
  priorityQuoting = 'vas_priority_quoting',
  directRequest = 'direct_request',
  ikeaEligibility = 'ikea_eligibility',
  discountAvailable = 'discount_available',
}

type LabelVasRelation = {
  [key in RequestLabelType]?: VasTypeEnum;
};

export const labelVasTypeRelations: LabelVasRelation = {
  [RequestLabelType.extraRadius]: VasTypeEnum.extraRadius,
  [RequestLabelType.extraOffer]: VasTypeEnum.extraOffer,
  [RequestLabelType.priorityQuoting]: VasTypeEnum.priorityQuoting,
};

export const getVasTypeByLabel = (labelType: RequestLabelType): VasTypeEnum | undefined =>
  labelVasTypeRelations[labelType];

export const isVasType = (type: string): boolean => type in VasTypeEnum;

export const getVasDaysRemaining = (expiresAt: string): number => {
  const endOfDay = new Date(expiresAt);
  endOfDay.setHours(23, 59, 59, 999);

  const diffInDays = Math.ceil((new Date().getTime() - endOfDay.getTime()) / 1000 / 60 / 60 / 24);
  return Math.abs(diffInDays);
};

export const TOUCH_POINT_BUTTON = 'premium_settings';
