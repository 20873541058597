import localStorage from 'utils/localStorage/localStorage';
import gql from 'graphql-tag';

const spAppInChatPromoDismissed = 'SP_APP_IN_CHAT_PROMOTION_DISMISSED';
const spAppGlobalPromoDismissed = 'SP_APP_GLOBAL_PROMOTION_DISMISSED';
const spAppGlobalPromoRepeat = 'SP_APP_GLOBAL_PROMOTION_REPEAT_AT';
const expirationPeriod = 45; // days
export type PlacementType = 'chat' | 'global';

export const hasBeenClosedRecently = (type: PlacementType): boolean => {
  if (type === 'global') {
    const count = Number(localStorage.getItem(spAppGlobalPromoDismissed)) || 0;
    if (count > 1) return true;

    const expDate = localStorage.getItem(spAppGlobalPromoRepeat);
    if (!expDate) return false;

    const now = new Date().getTime();
    const expirationTime = new Date(expDate).getTime();
    return now < expirationTime;
  } else {
    // in chat
    return Boolean(localStorage.getItem(spAppInChatPromoDismissed));
  }
};

export const setNextDisplayDate = (): void => {
  const now = new Date();
  const expirationTime = new Date().setDate(now.getDate() + expirationPeriod);
  const expDate = new Date(expirationTime).toISOString();
  localStorage.setItem(spAppGlobalPromoRepeat, expDate);
};

export const setBannerAsSeen = (type: PlacementType): void => {
  if (type === 'chat') localStorage.setItem(spAppInChatPromoDismissed, 1);
  if (type === 'global') {
    const value = localStorage.getItem(spAppGlobalPromoDismissed);
    const count = value ? Number(value) : 0;
    localStorage.setItem(spAppGlobalPromoDismissed, count + 1);
    setNextDisplayDate();
  }
};

export type ProviderProfileCompletion = {
  avatar?: boolean;
  gallery?: boolean;
  installApp?: boolean;
  workingArea?: boolean;
  checkCategories?: boolean;
};

export const profileCompletionQuery = gql`
  query ProviderProfileCompletion {
    me {
      id
      profileCompletion {
        installApp
      }
    }
  }
`;
