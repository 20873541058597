import { t } from '@lingui/macro';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import gql from 'graphql-tag';
import { RouteComponentProps } from 'react-router';

import { vasesQueryFragment } from 'Components/Settings/Premium/Premium.helpers';
import { NotificationItem } from 'common/helpers/commonTypes';
import { client } from 'utils/apolloConnector/apolloConnector';
import { TProviderRequestItem } from 'utils/graphql/providerQuotedRequests';

export type ChatMessage = {
  type: string;
  notSent?: boolean;
  [key: string]: any;
};

export type RequestConversation$Props = RouteComponentProps & {
  type: 'user' | 'provider';
  providerId: string;
  requestId: string;
  requestDetails: any;
  requestState: TProviderRequestItem['state'];
  requestQuoteState?: TProviderRequestItem['quoteState'];
  userId: string;
  currentUserId: string;
  loading: boolean;
  conversationShown: boolean;
  reviewAlreadySet: boolean;
  data: {
    messages: Array<ChatMessage>;
  };
  prefilledRatingValue: number;
  cityId?: string;
  categoryId?: string;
  activeConversationId?: string;
  canRateProvider?: boolean;
  isMuted?: boolean;
  isFake: boolean;
  freeSlots: number;
  busySlots: number;
  estimateModalOpen?: boolean;
  activeConversationReviewId: string;
  totalRequestsCount?: number;
  refetchRequestDetails: () => Promise<any>;
  refetchRequestData: () => Promise<any>;
  trackSendMessage: (event: string) => void;
  onToggleReview: (feedbackId: string, isDisabled: boolean) => Promise<void>;
  updateRequestStatusInstantlyTo?: (status: string) => Promise<any>;
  markNotificationsAsSeen?: () => void;
  triggerAddFeedbackModal?: (px: number, element: string) => void;
  onAskForReview?: (suggestedL4CategoryIds: string[]) => Promise<void>;
  onAskForReviewWithSuggestions?: (suggestedL3Ids: string[]) => Promise<void>;
  onUserContactAction?: () => void;
  onConversationStarted?: () => void;
  onPhoneShare?: () => void;
  onMessageReceived?: (message: NotificationItem) => void;
  trackChatEvent: (event: string) => void;
  onMuteConversation: (variables: any) => void;
  onScroll: (triggerScroll: boolean) => void;
  onGalleryUpload?: () => Promise<any>;
  handleEstimateOpen?: () => void;
  handleEstimateClose?: (price?: number) => void;
};

export enum MessageInputTypeEnum {
  text = 'text',
  image = 'image',
}

export type RequestConversation$State = {
  messages: ChatMessage[];
  controlsShown: boolean;
  loading: boolean;
  isGalleryModalOpen: boolean;
  isPhoneModalOpen: boolean;
};

export const MESSAGES_CONTAINER_ID = 'chatMessagesContainer';

export const instantActionTypes = {
  instant_review: 'instant_review',
  instant_reply: 'instant_reply',
  instant_ask_for_review: 'instant_ask_for_review',
};

export const messageTypes = {
  // CONTENT TYPES
  message: 'message',
  image: 'image',
  accepted_offer: 'accepted_offer',
  request_canceled: 'request_canceled',
  contact: 'contact',
  location: 'location',
  rating: 'rating',
  error: 'error',

  // SYSTEM TYPES
  system_rate_user: 'system_rate_user',
  system_request_canceled: 'system_request_canceled',
  system_request_finished: 'system_request_finished',
  system_ask_for_review: 'system_ask_for_review',
  system_dismiss_review: 'system_dismiss_review',
  system_provider_card: 'system_provider_card',
  system_receiver_seen: 'system_receiver_seen',
  system_new_messages: 'system_new_messages',
  system_conversation_muted: 'system_conversation_muted',
  system_conversation_unmuted: 'system_conversation_unmuted',
  system_provider_applied: 'system_provider_applied',
  system_provider_rated: 'system_provider_rated',
  system_user_seen: 'system_user_seen',
  system_refund_rating: 'system_refund_rating',
  system_refund_inactive_user: 'system_refund_inactive_user',
  system_reveal_clients_phone: 'system_reveal_clients_phone',
  system_reveal_providers_phone: 'system_reveal_providers_phone',
  system_pwf_state: 'system_pwf_state',
  system_pwf_payout: 'system_pwf_payout',
  system_pwf_refund: 'system_pwf_refund',
  system_pwf_points_reward: 'system_pwf_points_reward',
  system_pwf_paid_to_escrow: 'system_pwf_paid_to_escrow',
  system_pwf_dispute_opened: 'system_pwf_dispute_opened',
  system_pwf_dispute_closed: 'system_pwf_dispute_closed',

  // CUSTOM TYPES
  custom_explanation_message: 'custom_explanation_message',
  custom_request_details: 'custom_request_details',
  custom_creation_date: 'custom_creation_date',
  custom_call_to_conversation: 'custom_call_to_conversation',
  custom_instant_replies: 'custom_instant_replies',
  custom_quote_state: 'custom_quote_state',
} as const;

const renderableTypes = [
  messageTypes.message,
  messageTypes.image,
  messageTypes.contact,
  messageTypes.location,
  messageTypes.rating,
  messageTypes.system_ask_for_review,
  messageTypes.system_rate_user,
  messageTypes.system_conversation_muted,
  messageTypes.system_reveal_clients_phone,
  messageTypes.system_reveal_providers_phone,
  messageTypes.system_pwf_state,
  messageTypes.system_pwf_payout,
  messageTypes.system_pwf_refund,
  messageTypes.system_pwf_paid_to_escrow,
  messageTypes.system_pwf_dispute_opened,
  messageTypes.system_pwf_dispute_closed,
];

export const hasRenderableType = ({ type }: ChatMessage): boolean => {
  return renderableTypes.some((rType) => rType === type);
};

export const getMessageTypeSuffix = (type: keyof typeof messageTypes): string => {
  switch (type) {
    case messageTypes.message:
      return 'text';
    case messageTypes.image:
      return 'img';
    case messageTypes.accepted_offer:
    case messageTypes.request_canceled:
    case messageTypes.system_request_finished:
      return 'badge';
    case messageTypes.contact:
      return 'contact';
    case messageTypes.location:
      return 'location';
    case messageTypes.rating:
      return 'rating';
    case messageTypes.error:
      return 'error';
    case messageTypes.system_provider_card:
      return 'pro-card';
    case messageTypes.custom_call_to_conversation:
      return 'initiate-contact';
    case messageTypes.custom_request_details:
      return 'request-details';
    case messageTypes.system_ask_for_review:
      return 'ask-review';
    case messageTypes.custom_instant_replies:
      return 'quickReplies';
    case messageTypes.custom_explanation_message:
      return 'explanationMessage';
    case messageTypes.system_new_messages:
      return 'newMessages';
    case messageTypes.system_conversation_muted:
      return 'conversationMuted';
    case messageTypes.system_provider_rated:
      return 'providerRated';
    case messageTypes.system_user_seen:
      return 'userSeenRequest';
    case messageTypes.system_refund_rating:
    case messageTypes.system_pwf_points_reward:
      return 'refundRating';
    case messageTypes.system_refund_inactive_user:
      return 'refundInactiveUser';
    case messageTypes.system_request_canceled:
      return 'refundRequestCancelled';
    case messageTypes.system_reveal_clients_phone:
      return 'revealClientsPhone';
    case messageTypes.system_reveal_providers_phone:
      return 'revealProvidersPhone';
    case messageTypes.system_pwf_state:
      return 'pwf';
    case messageTypes.system_pwf_payout:
      return 'pwf';
    case messageTypes.system_pwf_refund:
      return 'pwf';
    case messageTypes.system_pwf_paid_to_escrow:
      return 'pwf';
    case messageTypes.system_pwf_dispute_opened:
      return 'pwf';
    case messageTypes.system_pwf_dispute_closed:
      return 'pwf';
    case messageTypes.custom_quote_state:
      return 'quoteState';
    default:
      return '';
  }
};

export const getMessageSpecificClassName = (type: keyof typeof messageTypes): string =>
  `messages__item_${getMessageTypeSuffix(type)}`;

export const userQuery = gql`
  query UserConversationMessages($requestId: ID!, $providerId: ID!, $l3Id: ID) {
    me {
      id
      firstName
      lastName
      guest
    }
    profile(id: $providerId) {
      id
      avatarUrl
      slug
      firstName
      lastName
      shortDescription
      dateRegistered
      rating
      feedbacksCount
      feedbackClientsCount
      companyName
      maskedPhone
      phoneVerified
      email
      websiteUrl
      isEmailPublic
      yearsOfExperience
      yearEstablished
      avgResponseTimeSeconds
      featured
      newcomer: isNewcomer
      company {
        id
        verified
      }
      gallery {
        name
        url
        title
      }
      city {
        id
        name
        region {
          id
          name
        }
      }
      feedbacks(last: 1) {
        comment
        authorFirstName
        cityName
      }
      specializations {
        id
        servicesL4 {
          id
          checked
        }
      }
      badges(l3Id: $l3Id) {
        id
        title
        tooltip
        actionUrl
        actionTitle
      }
    }
    messages(serviceRequestId: $requestId, providerId: $providerId) {
      type
      uuid
      visibleTo
      receiverId
      senderId
      timestamp
      content {
        ... on MessageContentText {
          text
        }
        ... on MessageContentImage {
          src
        }
        ... on MessageContentOffer {
          text
          price
          units
          unitsLabel
          unitsShortLabel
        }
        ... on MessageContentRating {
          id
          value
          text
          isDisabled
          isEditable
          isInternalByToken
          files
          status
          l4Categories {
            id
            name
          }
        }
        ... on MessageContentContact {
          type
          contactName
          contactValue
        }
        ... on MessageContentSystemNewMessages {
          text
        }
        ... on MessageContentSystemReceiverSeen {
          seenMessageUUID
          text
        }
        ... on MessageContentSystemRefundRating {
          text
          amount
        }
        ... on MessageContentPwfPaidToEscrow {
          amount
        }
        ... on MessageContentPwfPayout {
          amount
        }
        ... on MessageContentPwfRefund {
          amount
        }
      }
    }
  }
`;

export const providerQuery = gql`
  ${vasesQueryFragment}

  query ProviderConversationMessages($requestId: ID!, $providerId: ID!, $userId: ID!, $l3Id: ID) {
    me {
      id
      avatarUrl
      slug
      firstName
      lastName
      createdAt
      rating
      phone
      phoneVerified
      email
      emailVerified
      isEmailPublic
      guest
      city {
        id
        name
        region {
          id
          name
        }
      }
      reviews {
        id
      }
      contacts {
        id
        friendlyName
        type
        value
      }
      provider {
        id
        companyName
        websiteUrl
        company {
          id
          verified
        }
        vases {
          isAvailable
          isEnabled
          vas {
            ...ProviderVas
          }
        }
      }
    }
    wallet {
      points {
        amount
        amountText
        amountAlert
        validityText
        validityAlert
      }
    }
    profile(id: $userId) {
      id
      firstName
      lastName
      avatarUrl
      slug
    }
    providerAdditionalData: profile(id: $providerId) {
      id
      shortDescription
      yearsOfExperience
      yearEstablished
      dateRegistered
      avgResponseTimeSeconds
      featured
      newcomer: isNewcomer
      feedbackClientsCount
      websiteUrl
      company {
        id
        verified
      }
      badges(l3Id: $l3Id) {
        id
        title
        tooltip
        actionUrl
        actionTitle
      }
      gallery {
        name
        url
        title
      }
      feedbacks(last: 1) {
        comment
        authorFirstName
        cityName
      }
      specializations {
        id
        servicesL4 {
          id
          checked
        }
      }
    }
    messages(serviceRequestId: $requestId, providerId: $providerId) {
      type
      uuid
      visibleTo
      receiverId
      senderId
      timestamp
      content {
        ... on MessageContentText {
          text
        }
        ... on MessageContentImage {
          src
        }
        ... on MessageContentOffer {
          text
          price
          units
          unitsLabel
          unitsShortLabel
        }
        ... on MessageContentRating {
          id
          value
          text
          isDisabled
          isEditable
          isInternalByToken
          files
          status
          l4Categories {
            id
            name
          }
        }
        ... on MessageContentContact {
          type
          contactName
          contactValue
        }
        ... on MessageContentSystemNewMessages {
          text
        }
        ... on MessageContentSystemReceiverSeen {
          seenMessageUUID
          text
        }
        ... on MessageContentSystemConversationMuted {
          username
          text
        }
        ... on MessageContentSystemAskForReview {
          text
          phone
          suggestedL4Categories {
            id
            name
          }
        }
        ... on MessageContentSystemProviderRated {
          text
          provider {
            id
            featured
            newcomer: isNewcomer
            avatar
            feedbackCount
            rating
            review {
              id
              createdAt
              rating
              text
            }
          }
        }
        ... on MessageContentSystemRefundRating {
          text
          amount
        }
        ... on MessageContentSystemRefundInactiveUser {
          text
          amount
        }
        ... on MessageContentSystemRequestCanceled {
          text
          amount
        }
        ... on MessageContentSystemRevealPhone {
          text
          amount
        }
        ... on MessageContentSystemRevealProvidersPhone {
          text
        }
        ... on MessageContentPwfPaidToEscrow {
          amount
        }
        ... on MessageContentPwfPayout {
          amount
        }
        ... on MessageContentPwfRefund {
          amount
        }
        ... on MessageContentPwfPointsReward {
          text
          amount
        }
      }
    }
    providerHint {
      iconUrl
      content
    }
  }
`;

export const dismissReviewRequestMutation = gql`
  mutation dismissReviewRequest($requestId: ID!, $providerId: ID!) {
    dismissReview(requestId: $requestId, providerId: $providerId)
  }
`;

const requestNotInterestedMutation = gql`
  mutation makeRequestUninterested($requestId: ID!) {
    makeRequestUninterested(requestId: $requestId)
  }
`;

type MakeRequestUninterestedVars = {
  requestId: string;
};

export const dismissRequest = (requestId: string) => {
  return client.mutate<{ makeRequestUninterested: boolean }, MakeRequestUninterestedVars>({
    mutation: requestNotInterestedMutation,
    variables: { requestId },
  });
};

const startConversationMutation = gql`
  mutation startConversationMutation($requestId: ID!, $offerPrice: Int) {
    startConversation(requestId: $requestId, offerPrice: $offerPrice)
  }
`;

type StartConversationVars = {
  requestId: string;
  offerPrice?: number;
};

export const startConversation = (requestId: string, offerPrice?: number) => {
  return client.mutate<{ startConversation: boolean }, StartConversationVars>({
    mutation: startConversationMutation,
    variables: {
      requestId,
      offerPrice,
    },
  });
};

export const trackPhoneClickMutation = gql`
  mutation TrackPhoneClick($requestId: ID!, $providerId: ID!) {
    trackPhoneClick(requestId: $requestId, providerId: $providerId)
  }
`;

type TrackPhoneClickVars = {
  requestId: string;
  providerId: string;
};
/**
 * @deprecated Use revealProviderPhone mutation instead
 */
export const trackPhoneClick = (requestId: string, providerId: string) => {
  return client.mutate<{ trackPhoneClick: boolean }, TrackPhoneClickVars>({
    mutation: trackPhoneClickMutation,
    variables: {
      requestId,
      providerId,
    },
  });
};

export const updateProviderSettingsMutation = gql`
  mutation UpdateProviderSettingsMutation($input: ProviderSettingsUpdateRequest!) {
    updateProviderSettings(input: $input)
  }
`;

export type GalleryImageItem = {
  name: string;
  order: number;
  title?: string;
};

type UpdateProviderSettingsVars = {
  input: {
    gallery: {
      images: GalleryImageItem[];
    };
  };
};

export const updateProviderGallery = (images: GalleryImageItem[]) => {
  return client.mutate<{ updateProviderSettings: boolean }, UpdateProviderSettingsVars>({
    mutation: updateProviderSettingsMutation,
    variables: {
      input: {
        gallery: {
          images,
        },
      },
    },
  });
};

export const sendMessageMutation = gql`
  mutation SendMessageMutation($message: SendMessageInput!) {
    sendMessage(message: $message)
  }
`;

type SendMessageVars = {
  message: {
    content: string;
    providerId: string;
    requestId: string;
    type: MessageInputTypeEnum;
    uuid: string;
  };
};

type SendMessageResponse = {
  sendMessage: boolean;
};

export const sendMessage = (message: SendMessageVars['message']) => {
  return client.mutate<SendMessageResponse, SendMessageVars>({
    mutation: sendMessageMutation,
    variables: {
      message,
    },
  });
};

export const messageFragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [
        {
          kind: 'INTERFACE',
          name: 'MessageContent',
          possibleTypes: [
            { name: 'MessageContentText' },
            { name: 'MessageContentImage' },
            { name: 'MessageContentOffer' },
            { name: 'MessageContentRating' },
            { name: 'MessageContentContact' },
            { name: 'MessageContentSystemNewMessages' },
            { name: 'MessageContentSystemReceiverSeen' },
            { name: 'MessageContentSystemConversationMuted' },
            { name: 'MessageContentSystemProviderRated' },
            { name: 'MessageContentSystemRefundRating' },
            { name: 'MessageContentSystemRevealPhone' },
          ],
        },
        {
          kind: 'INTERFACE',
          name: 'FeedItemContent',
          possibleTypes: [{ name: 'FeedItemContentConversation' }, { name: 'FeedItemContentReview' }],
        },
      ],
    },
  },
});

const MULTI_LB_REGEXP = /\n+/g;
const MULTI_WHITESPACE_REGEXP = /[ |\t]{2,}/g;
export const normalizeMessageContent = (content?: ChatMessage) =>
  content && typeof content.text === 'string'
    ? // NOTE: one regext is replacing only multiple line breakes and the other -- multiple whitespaces (without LBs)
      {
        ...content,
        text: content.text.replace(MULTI_LB_REGEXP, '\n').replace(MULTI_WHITESPACE_REGEXP, ' ').trim(),
      }
    : content;

export const getIkeaLeadText = (price: string): string => {
  return t`Sugerowana cena montażu: ${price}. Masz prawo uzgodnić inną cenę ze Zlecającym`;
};

export const extractIkeaPrice = (details: any[]): string | undefined => {
  const ikeaItem = details.find((item) => item.questionId === 'service-ikea-price');
  if (ikeaItem) return ikeaItem.answer;
};
