import { ApolloProvider } from '@apollo/react-common';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { pl as pluralsPl } from 'make-plural/plurals';
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Router } from 'react-router-dom';

import config from './config/config';
import CurrentUser from 'Components/CurrentUser/CurrentUser';
import Entry from 'Components/Entry/Entry';
import { LocalSettingsProvider } from 'Components/LocalSettingsProvider/LocalSettingsProvider';
import { SnackbarProvider } from 'uikit/Snackbar';
// Should create instance of FCMManager
import 'utils/FCMManager/FCMManager';
// PAGES
import { client } from 'utils/apolloConnector/apolloConnector';
import { focusManager } from 'utils/focusManager/focusManager';
import history from 'utils/history/history';

import './App.scss';
/* @ts-expect-error type mismatch */
import { messages } from './locales/pl/messages';
import { Provider as LQProvider, getCookieName, getFeatureFlagCookieName } from '@olx/react-laquesis';
import { getCookie } from 'utils/cookies/cookieUtils';
import { cookieStorage, initializeCookieStorage, ENV } from 'utils/cookies/cookieStorage';
import { useLoad3rdPartyScripts } from 'utils/thirdPartyScripts';
import loadable from '@loadable/component';

export const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

enum Status {
  idle = 'idle',
  rejected = 'rejected',
  resolved = 'resolved',
  pending = 'pending',
}

const locale = config.LOCALE;

i18n.loadLocaleData({
  [locale]: { plurals: pluralsPl },
});
i18n.load(locale, messages);
i18n.activate(locale);
focusManager();

const CookieStorageProvider = loadable(() =>
  import('@olxeu-eprivacy-storage/react').then((module) => module.CookieStorageProvider)
);

const App = () => {
  const [initialScriptsStatus, setInitialScriptsStatus] = useState(Status.idle);
  useLoad3rdPartyScripts();
  const [lquesisCookies, setLaquesisCookies] = useState<{
    lqCookie: string | null;
    lqFeatureFlagCookie: string | null;
  }>({
    lqCookie: null,
    lqFeatureFlagCookie: null,
  });

  const cookieStorageInit = useCallback(async () => {
    try {
      setInitialScriptsStatus(Status.pending);
      // This has to run before any code that wants to access cookie runs,
      // including rendering components or making API calls
      const isProduction = process.env.NODE_ENV !== 'production';
      const environment = isProduction ? ENV.STG : ENV.PRD;
      const debugMode = isProduction ? true : false;
      await initializeCookieStorage(environment, debugMode);
      setInitialScriptsStatus(Status.resolved);
    } catch (error) {
      setInitialScriptsStatus(Status.rejected);
      console.error(error);
    }
  }, []);

  useIsomorphicLayoutEffect(() => {
    cookieStorageInit();
  }, [cookieStorageInit]);

  useEffect(() => {
    if (initialScriptsStatus === Status.resolved) {
      setLaquesisCookies({
        lqCookie: getCookie(getCookieName()),
        lqFeatureFlagCookie: getCookie(getFeatureFlagCookieName()),
      });
    }
  }, [initialScriptsStatus]);

  return (
    <LQProvider cookie={lquesisCookies?.lqCookie || ''} cookieff={lquesisCookies?.lqFeatureFlagCookie || ''}>
      <CookieStorageProvider storage={cookieStorage}>
        <CurrentUser>
          <LocalSettingsProvider>
            <HelmetProvider>
              <ApolloProvider client={client}>
                <I18nProvider i18n={i18n}>
                  <SnackbarProvider>
                    <Router history={history}>
                      <Entry />
                    </Router>
                  </SnackbarProvider>
                </I18nProvider>
              </ApolloProvider>
            </HelmetProvider>
          </LocalSettingsProvider>
        </CurrentUser>
      </CookieStorageProvider>
    </LQProvider>
  );
};

export default App;
