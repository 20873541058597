export enum VasTypeEnum {
  extraRadius = 'extra_radius',
  extraOffer = 'extra_offer',
  priorityQuoting = 'priority_quoting',
  unlimitedQuoting = 'unlimited_quoting',
  /**
   * @deprecated
   */
  olxAd = 'olx_ad',
  contactCard = 'contact_card',
  pointsPackage = 'points_package',
}

export type TVas = {
  id: string;
  slug: string;
  imageUrl: string;
  type: VasTypeEnum;
  name: string;
  shortName: string;
  description: string;
  price: number;
  discount: number;
  /**
   * @deprecated
   */
  salePrice: number;
  /**
   * @deprecated
   */
  promoDays: number;
  expirationDays: number;
};

export type TProviderVas = {
  isEnabled: boolean;
  /**
   * @deprecated
   */
  isSubscriptionEnabled: boolean;
  isInactive: boolean;
  isSubscription: boolean;
  canBeProlonged: boolean;
  /**
   * @deprecated
   */
  canRenewSubscription: boolean;
  expiresAt: string;
  vas: TVas;
  isAvailable: boolean;
};
