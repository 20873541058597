import { useQuery } from '@apollo/react-hooks';
import { Plural, Trans } from '@lingui/macro';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

import ComplexLoginModal from 'Components/ComplexLogin/ComplexLoginModal';
import HeaderRating from 'Components/HeaderRating/HeaderRating.async';
import HeaderSoundTrigger from 'Components/HeaderSoundTrigger/HeaderSoundTrigger';
import { REQUEST_PASSWORD_HASH } from 'Components/Login/Login.helpers';
import LoginModal from 'Components/Login/LoginModal';
import ProviderHeaderPoints from 'Components/ProviderHeaderPoints/ProviderHeaderPoints.async';
import { Header$User, userDataQuery } from 'common/Header/Header.helpers';
import HeaderNotifications from 'common/HeaderNotifications/HeaderNotifications.async';
import isMobile from 'common/helpers/platformDetector';
import { useSnackbar } from 'uikit/Snackbar';
import { TextP3 } from 'uikit/Text';
import analytics from 'utils/analytics/analytics';
import routes from 'utils/routeTranslator';

import AllServicesNav from './AllServicesNav/AllServicesNav';
import GuestNavigation from './GuestNavigation';
import MobileNavigation from './MobileNavigation/MobileNavigation.async';
import './Navigation.scss';
import UserMenu from './UserMenu/UserMenu.async';

type Navigation$Props = {
  isProvider: boolean;
  isLoggedIn: boolean;
};

const Navigation: FC<Navigation$Props> = (props) => {
  const { data } = useQuery<{
    me: Header$User;
    activeUserRequestsCount?: number;
  }>(userDataQuery, {
    skip: !props.isLoggedIn,
    ssr: false,
  });

  const history = useHistory();
  const { search } = useLocation();
  const [isComplexLoginOpen, setIsComplexLoginOpen] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isPasswordStep, setIsPasswordStep] = useState(false);
  const { showSnackbar } = useSnackbar();

  // Displays modal: remind user to set password
  useEffect(() => {
    if (history.location.hash === `#${REQUEST_PASSWORD_HASH}`) {
      if (data?.me.guest) {
        setIsPasswordStep(true);
        openLogin();
      } else if (data?.me) {
        history.push({ hash: '' });
      }
    }
  }, [data?.me, data?.me.guest, history, history.location.hash]);

  // Displays alert: notify OLX user about successful sign-up
  useEffect(() => {
    if (data) {
      const params = new URLSearchParams(search);

      if (params.get('registered_from') === 'olx') {
        const email = data.me.email;
        showSnackbar({
          content: (
            <>
              <TextP3 bold className="navigation__greeting">
                <Trans>Witaj na Fixly – serwisie Grupy OLX, gdzie szybko zdobędziesz jeszcze więcej zleceń!</Trans>
              </TextP3>
              <div className="navigation__account">
                <Trans>
                  Jesteś zalogowany jako: <strong>{email}</strong>
                </Trans>
              </div>
            </>
          ),
          variant: 'success',
        });

        params.delete('registered_from');
        history.replace({
          search: params.toString(),
        });
      }
    }
  }, [data, history, search, showSnackbar]);

  const openComplexLogin = () => {
    analytics.global.loginClick('my_requests');
    setIsComplexLoginOpen(true);
  };

  const closeComplexLogin = () => {
    setIsComplexLoginOpen(false);
  };

  const openLogin = () => {
    analytics.global.loginClick();
    setIsLoginOpen(true);
  };

  const closeLogin = () => {
    setIsLoginOpen(false);
  };

  const renderLoggedInProviderNav = (onboarding: boolean) => {
    if (onboarding) return null;

    return (
      <ul className="navigation__list">
        <li className="navigation__item navigation__item_wallet">
          <ProviderHeaderPoints />
        </li>
        <li className="navigation__item navigation__item_rating">
          <HeaderRating />
        </li>
      </ul>
    );
  };

  const renderLoggedInUserNav = () => {
    return (
      <ul className="navigation__list">
        <li className="navigation__item">
          <AllServicesNav />
        </li>
        <li className="navigation__item">
          <a href={routes.get('front::dashboard')} className="navigation__link" data-testid="navigationMyRequests">
            <Plural value={data?.activeUserRequestsCount || 0} one="Moje zapytania" other="Moje zapytania (#)" />
          </a>
        </li>
      </ul>
    );
  };

  const renderLoggedInNav = (isProvider: boolean, onboarding: boolean) => {
    return isProvider ? renderLoggedInProviderNav(onboarding) : renderLoggedInUserNav();
  };

  const { isProvider } = props;
  const me = data?.me;

  const onboarding = Boolean(me?.provider?.onboardingStep);
  const activeUserRequestsCount = data?.activeUserRequestsCount;
  const username = [me?.firstName, me?.lastName].filter((item) => Boolean(item)).join(' ');

  return (
    <nav className="navigation">
      {me ? (
        renderLoggedInNav(isProvider, onboarding)
      ) : (
        <GuestNavigation onLoginOpen={openLogin} onComplexLoginOpen={openComplexLogin} providerView={isProvider} />
      )}

      {me && (
        <>
          <HeaderSoundTrigger />
          {!onboarding && <HeaderNotifications isProvider={isProvider} />}
          <UserMenu username={username} avatarUrl={me.avatarUrl} isProvider={isProvider} onboarding={onboarding} />
        </>
      )}

      {isMobile.isAnyMobile() && (
        <div className="navigation__mobile">
          <MobileNavigation
            isProvider={isProvider}
            isLoggedIn={Boolean(me)}
            userSlug={me ? me?.slug : ''}
            onLoginClick={openLogin}
            onComplexLoginClick={openComplexLogin}
            onboarding={onboarding}
            activeUserRequestsCount={activeUserRequestsCount || 0}
          />
        </div>
      )}

      {isLoginOpen && <LoginModal isOpen={isLoginOpen} onClose={closeLogin} isPasswordStep={isPasswordStep} />}
      {isComplexLoginOpen && <ComplexLoginModal isOpen={isComplexLoginOpen} onClose={closeComplexLogin} />}
    </nav>
  );
};

export default Navigation;
