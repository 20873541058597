import config from 'config/config';

import { apiHeaders } from '../api/headers';
import localStorage from '../localStorage/localStorage';
import { createLogger, logDevOnly } from '../logger/logger';

declare global {
  interface Window {
    auth: Auth;
  }
}

const log = createLogger(logDevOnly, 'Auth');

const { API_URL } = config;

type ClientCred = {
  accessToken?: string;
  refreshToken?: string;
  isProvider?: boolean;
  isImpersonating?: boolean;
  userId?: number | string;
  /** unix timestamp */
  expiresAt?: number;
};

type StorageKeys = {
  access: 'accessToken';
  refresh: 'refreshToken';
  isPro: 'isProvider';
  isImpersonating: 'isImpersonating';
  userId: 'userId';
  refreshTime: 'lastRefreshTime';
  tokenLifeTimeMs: 'tokenLifeTimeMs';
  tokenRefreshTimeoutMs: 'tokenRefreshTimeoutMs';
};

class Auth {
  static storageKeys: StorageKeys = {
    access: 'accessToken',
    refresh: 'refreshToken',
    isPro: 'isProvider',
    isImpersonating: 'isImpersonating',
    userId: 'userId',
    refreshTime: 'lastRefreshTime',
    tokenLifeTimeMs: 'tokenLifeTimeMs',
    tokenRefreshTimeoutMs: 'tokenRefreshTimeoutMs',
  };

  refreshingAccessToken?: Promise<boolean>;

  constructor() {
    this.attachLogoutListener();
  }

  private attachLogoutListener() {
    if (typeof window === 'undefined') return;

    const logoutMenuItem = document.querySelector('.userNav__link_logout');
    if (!logoutMenuItem) return;

    logoutMenuItem.addEventListener('click', () => this.clearCredentials());
  }

  private updateExpiresAt(expiresAt?: number) {
    if (typeof window === 'undefined') return;
    if (!expiresAt) return;

    const expiresAtInMs = expiresAt * 1000;
    const now = new Date();
    const tokenLifeTimeMs = expiresAtInMs - now.getTime();
    localStorage.setItem(Auth.storageKeys.tokenLifeTimeMs, String(tokenLifeTimeMs));

    const tokenRefreshTimeoutMs = tokenLifeTimeMs * 0.75;
    localStorage.setItem(Auth.storageKeys.tokenRefreshTimeoutMs, String(tokenRefreshTimeoutMs));

    window.setTimeout(async () => {
      await this.refresh();
    }, Number(tokenRefreshTimeoutMs));
  }

  storeCurrentUser(currentUser: ClientCred): void {
    if (currentUser.userId) {
      localStorage.setItem(Auth.storageKeys.userId, String(currentUser.userId));
      localStorage.setItem(Auth.storageKeys.isPro, String(currentUser.isProvider || false));
      localStorage.setItem(Auth.storageKeys.isImpersonating, String(currentUser.isImpersonating || false));

      // @fixme remove window.FIX: This is only used in sentry. @todo move sentry to google tag manager
      if (typeof window !== 'undefined') {
        window.FIX = window.FIX || {};
        window.FIX.current_user = currentUser.userId;
      }
    } else {
      localStorage.removeItem(Auth.storageKeys.userId);
      localStorage.removeItem(Auth.storageKeys.isPro);
    }
  }

  storeCredentials(credentials: ClientCred): void {
    log('[auth] storeCredentials:', { credentials });

    this.storeCurrentUser(credentials);

    localStorage.setItem(Auth.storageKeys.access, credentials.accessToken);
    localStorage.setItem(Auth.storageKeys.refresh, credentials.refreshToken);
    localStorage.setItem(Auth.storageKeys.refreshTime, String(new Date()));

    this.updateExpiresAt(credentials.expiresAt);
  }

  clearCredentials(): void {
    log('[auth] clearCredentials:', {
      access: localStorage.getItem(Auth.storageKeys.access),
      refresh: localStorage.getItem(Auth.storageKeys.refresh),
      isPro: localStorage.getItem(Auth.storageKeys.isPro),
      userId: localStorage.getItem(Auth.storageKeys.userId),
      refreshTime: localStorage.getItem(Auth.storageKeys.refreshTime),
      tokenLifeTimeMs: localStorage.getItem(Auth.storageKeys.tokenLifeTimeMs),
      tokenRefreshTimeoutMs: localStorage.getItem(Auth.storageKeys.tokenRefreshTimeoutMs),
    });

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in Auth.storageKeys) {
      // noinspection JSUnfilteredForInLoop
      localStorage.removeItem(Auth.storageKeys[key as keyof StorageKeys]);
    }
  }

  private async refreshToken(): Promise<boolean> {
    // Refresh token is missing. Stop
    const refreshToken = localStorage.getItem(Auth.storageKeys.refresh);
    if (!refreshToken) return false;

    const response = await fetch(`${API_URL}/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...apiHeaders,
      },
      credentials: 'include',
      body: JSON.stringify({
        refreshToken,
      }),
    });

    if (!response.ok) return false;

    const body: { accessToken: string; expiresAt: number; refreshToken?: string } = await response.json();
    const { accessToken, expiresAt, refreshToken: updatedRefreshToken } = body;
    if (!accessToken || !expiresAt) return false;

    localStorage.setItem(Auth.storageKeys.access, accessToken);
    localStorage.setItem(Auth.storageKeys.refreshTime, String(new Date()));

    if (updatedRefreshToken) {
      localStorage.setItem(Auth.storageKeys.refresh, updatedRefreshToken);
    }

    this.updateExpiresAt(expiresAt);
    log('[auth] refreshToken success:', {
      accessToken,
      expiresAt,
      refreshTime: localStorage.getItem(Auth.storageKeys.refreshTime),
      tokenLifeTimeMs: localStorage.getItem(Auth.storageKeys.tokenLifeTimeMs),
      tokenRefreshTimeoutMs: localStorage.getItem(Auth.storageKeys.tokenRefreshTimeoutMs),
    });

    return true;
  }

  async refresh(): Promise<boolean> {
    if (typeof window === 'undefined') return false;

    if (this.refreshingAccessToken != null) return this.refreshingAccessToken;

    // Refresh promise "singleton" with "lazy init"
    this.refreshingAccessToken = this.refreshToken();

    const refreshResult = await this.refreshingAccessToken;

    // Cleanup session on failed refresh
    if (!refreshResult) this.clearCredentials();

    // Unset resolved refresh promise
    this.refreshingAccessToken = undefined;

    return refreshResult;
  }

  async getAccessTokenWithRefresh(): Promise<string | undefined> {
    if (typeof window === 'undefined') return;

    const refreshToken = localStorage.getItem(Auth.storageKeys.refresh);
    if (!refreshToken) return;

    if (this.isExpired()) await this.refresh();

    return localStorage.getItem(Auth.storageKeys.access) || undefined;
  }

  private isExpired(): boolean {
    if (typeof window === 'undefined') return true;

    const accessToken = localStorage.getItem(Auth.storageKeys.access);
    const refreshDateString = localStorage.getItem(Auth.storageKeys.refreshTime);
    const tokenLifeTimeMs = localStorage.getItem(Auth.storageKeys.tokenLifeTimeMs);

    if (!refreshDateString || !accessToken || !tokenLifeTimeMs) return true;

    const tokenValidityTime = new Date(Date.parse(refreshDateString) + Number(tokenLifeTimeMs));

    return tokenValidityTime < new Date();
  }

  isLoggedIn(): boolean {
    return !this.isExpired();
  }

  isProvider(): boolean {
    if (this.isExpired()) return false;
    return localStorage.getItem(Auth.storageKeys.isPro) === 'true';
  }

  isImpersonating(): boolean {
    if (this.isExpired()) return false;
    return localStorage.getItem(Auth.storageKeys.isImpersonating) === 'true';
  }

  getUserId(): string {
    if (this.isExpired()) return '';
    return localStorage.getItem(Auth.storageKeys.userId) || '';
  }
}

let authInstance = null;

export default (() => {
  if (!authInstance) {
    authInstance = new Auth();
  }

  if (process.env.NODE_ENV === 'development' && typeof window !== 'undefined') {
    window.auth = authInstance;
  }

  return authInstance;
})();
