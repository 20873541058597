import { useQuery } from '@apollo/react-hooks';
import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

import NotFound from 'Components/NotFound/NotFound';
import { ProfileRedirectResponse, slugAliasRedirectQuery } from 'utils/graphql/slug';
import RedirectWithStatus from './RedirectWithStatus';

const WildcardRoute: FC = () => {
  const { pathname } = useLocation();

  // checking if it matches any slug alias
  const { data } = useQuery<ProfileRedirectResponse>(slugAliasRedirectQuery, {
    variables: {
      pathname: pathname,
    },
  });

  if (data) {
    const response = data.slugAliasRedirect;

    if (response.statusCode === 301) {
      return <RedirectWithStatus to={response.pathname} status={response.statusCode} />;
    }

    if (response.statusCode === 404) return <NotFound />;
  }

  return null;
};

export default WildcardRoute;
