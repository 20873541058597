/* eslint-disable import/no-cycle, no-param-reassign, camelcase */
import { fetchCurrentUser } from 'Components/CurrentUser/CurrentUser.helpers';
import { isBrowser, isWindow } from 'common/helpers/helpers';
import parseQueryString from 'common/helpers/parseQueryString';
import config from 'config/config';
import auth from 'utils/auth/auth';
import { captureException, addBreadcrumb } from 'utils/errorLogger/sentry';
import { createLogger, logDevOnly } from 'utils/logger/logger';

import { getPageLabelByUrl } from './analytics.helpers';
import { cookieStorage } from 'utils/cookies/cookieStorage';

type Question = {
  id: string;
  type: string;
  format: string;
  nb: string;
  total: string;
  direction: string;
  wizard_flow_id: string;
};

type City = {
  id: string;
  name: string;
};

type Callback = () => void;

type EventParams = {
  /** @fixme union { trackEvent } | { trackLinkEvent } */
  trackLinkEvent?: string[] | unknown;
  trackEvent?: string[];
  linkCallBack?: Callback;

  entry_point?: string;
  purchase_point?: string;
  payment_method?: string;
  permanent_referer?: string;
  user_id?: number | string;
  is_professional?: boolean;
  impersonate?: boolean;
  gclid?: string;
  platform?: string;
  user_status?: string;
  event_type?: string;
  language?: string;

  [key: string]: unknown;
};

declare global {
  interface Window {
    hj?: Function;
    dataLayer?: any[];
  }
}
let FIX: any = {};

const log = createLogger(logDevOnly, 'Analytics DISABLED');
const LOG_ENABLED = !config.ANALYTICS_ENABLED;

function captureAnalyticsError() {
  captureException(new Error('Ninja loading error'));
}

if (isWindow) {
  FIX = window.FIX || {};
  window.dataLayer = window.dataLayer || [];

  if (FIX.features && FIX.features.analyticsLoadingError) {
    captureAnalyticsError();
  } else {
    window.addEventListener('message', (e) => {
      if (e.origin !== window.origin || e.data.type !== 'ninja_loading_error') {
        return;
      }
      captureAnalyticsError();
    });
  }
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    opera?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    MSStream?: any;
  }
}

function getMobileOperatingSystem() {
  if (isBrowser && navigator) {
    const userAgent = navigator?.vendor || navigator?.userAgent || window.opera;
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }
  }

  return 'unknown';
}

export function initPlatformType() {
  const PLATFORM_DESKTOP = 'desktop';
  const PLATFORM_RWD = 'rwd';
  const viewportWidth = !isWindow ? 1920 : Math.max(document.documentElement.clientWidth, window.innerWidth || 0); // @fixme ssr

  const mobileOperatingSystem = getMobileOperatingSystem();
  if (mobileOperatingSystem) return mobileOperatingSystem;

  return viewportWidth <= 568 ? PLATFORM_RWD : PLATFORM_DESKTOP;
}

// Shared variables with platform type inside
let platformType = initPlatformType();

export function getPlatformType() {
  return platformType || initPlatformType();
}

const paymentEntryPointKey = 'fixlyPaymentEntryPointKey';
const paymentPurchasePointKey = 'fixlyPaymentPurchasePointKey';
const paymentMethodKey = 'fixlyPaymentMethodKey';

const handleEventParams = (params: EventParams) => {
  const { entry_point, purchase_point, payment_method } = params;
  if (entry_point) {
    sessionStorage.setItem(paymentEntryPointKey, String(entry_point));
  }
  if (purchase_point) {
    sessionStorage.setItem(paymentPurchasePointKey, String(purchase_point));
  }
  if (payment_method) {
    sessionStorage.setItem(paymentMethodKey, String(payment_method));
  }
};

export const getPaymentPoints = (): {
  entry_point?: string;
  purchase_point?: string;
  payment_method?: string;
} => ({
  entry_point: sessionStorage.getItem(paymentEntryPointKey) || undefined,
  purchase_point: sessionStorage.getItem(paymentPurchasePointKey) || undefined,
  payment_method: sessionStorage.getItem(paymentMethodKey) || undefined,
});

//
// Permanent referer
//

function setPermanentReferer() {
  if (!isWindow) {
    return '';
  }
  const { referrer } = document;
  if (!referrer || referrer.startsWith(config.SITE_URL)) {
    return '';
  }

  const expirationTime = new Date();
  expirationTime.setMonth(expirationTime.getMonth() + 1);
  if (cookieStorage.isInitialized) {
    cookieStorage.set('permanent_referer', referrer, { expires: expirationTime.toUTCString() });
    return referrer;
  }
  return '';
}

function getPermanentReferer() {
  if (!isWindow) {
    return '';
  }
  let referer = null;
  if (cookieStorage.isInitialized) {
    referer = cookieStorage.get('permanent_referer');
  }
  if (!referer) {
    return setPermanentReferer();
  }

  return referer;
}

//
// GCLID parameter
//

function setGclidParam() {
  if (!isWindow) {
    return;
  }

  const { gclid } = parseQueryString(window.location.search);
  if (!gclid) {
    return;
  }

  const expirationTime = new Date();
  expirationTime.setMonth(expirationTime.getMonth() + 1);
  cookieStorage.set('gclid', gclid, { expires: expirationTime.toUTCString(), path: '/' });
}

function getGclidParam() {
  if (!isWindow) {
    return '';
  }
  if (cookieStorage.isInitialized) {
    const glcid = cookieStorage.get('gclid') || undefined;
    return glcid;
  }
  return '';
}

const trackHotjarEvent = (params: EventParams) => {
  if (!isWindow) {
    return;
  }
  if (typeof window.hj === 'undefined') {
    return;
  }

  const eventName = params.trackLinkEvent || params.trackEvent;
  if (!eventName || !Array.isArray(eventName) || eventName.length === 0) {
    return;
  }

  window.hj('tagRecording', eventName);
};

async function trackEvent(params: EventParams) {
  if (!isWindow) {
    return;
  }

  handleEventParams(params);

  setGclidParam();

  // @todo window.FIX
  if (FIX && FIX.features) {
    config.ANALYTICS_ENABLED = FIX.features.analyticsEnabled;
  }

  await fetchCurrentUser();

  if (params.linkCallBack) {
    let linkCallBackFired = false;
    const linkCallback = params.linkCallBack;

    params.linkCallBack = () => {
      linkCallBackFired = true;
      if (typeof linkCallback === 'function') {
        linkCallback();
      }
    };

    setTimeout(() => {
      if (linkCallBackFired === false) {
        // eslint-disable-next-line
        console.warn(`Link callback fires. Analytics are hanged up with params:`, params);
        if (typeof linkCallback === 'function') {
          linkCallback();
        }
      }
    }, config.ANALYTICS_CALLBACK_TIMEOUT);
  }

  const permanentReferer = getPermanentReferer();
  if (permanentReferer) {
    params.permanent_referer = permanentReferer;
  }

  const userId = auth.getUserId();

  if (userId) {
    params.user_id = userId;
    params.is_professional = auth.isProvider();
    params.impersonate = auth.isImpersonating();
  }

  const gclid = getGclidParam();
  if (gclid) {
    params.gclid = gclid;
  }

  params.platform = getPlatformType();
  params.user_status = userId ? 'logged' : 'unlogged';
  params.event_type = params.event_type || 'click';
  params.language = 'pl_PL';

  // Pass the experiment key in params in order to track variant_name
  // @todo remove window.FIX
  if (FIX.features && FIX.features.experiments) {
    const experimentKeys = Object.keys(FIX.features.experiments);
    let experimentCounter = 0;
    experimentKeys.forEach((expKey) => {
      const expConfig = FIX.features.experiments[expKey];
      if (expConfig.enabled && expConfig.name && expConfig.variantName) {
        experimentCounter += 1;
        const paramName = `variant_name${experimentCounter > 1 ? experimentCounter : ''}`;
        if (params && !params[paramName]) {
          params[paramName] = `${expConfig.name}_${expConfig.variantName}`;
        }
      }
    });
  }

  // @todo remove window.FIX
  if (FIX.pageLabel && !params.touch_point_page) {
    params.touch_point_page = FIX.pageLabel;
  }

  if (!config.ANALYTICS_ENABLED && LOG_ENABLED) {
    log(params);

    if (typeof params.linkCallBack === 'function') {
      params.linkCallBack();
    }

    // Add sentry breadcrumb
    addBreadcrumb({
      category: 'analytics',
      message: '[Analytics DISABLED] Business analytics event',
      data: params,
      level: 'info',
      type: 'default',
    });

    return;
  }

  if (window.dataLayer) {
    window.dataLayer.push(params);
  }

  trackHotjarEvent(params);

  // Add sentry breadcrumb
  addBreadcrumb({
    category: 'analytics',
    message: 'Business analytics event',
    data: params,
    level: 'info',
    type: 'default',
  });
}

function cleanupTrackEvent(params: EventParams) {
  if (!config.ANALYTICS_ENABLED) {
    trackEvent(params);
    return;
  }

  const trackTimeout = setTimeout(() => {
    trackEvent(params);
  }, config.ANALYTICS_CALLBACK_TIMEOUT || 2000);

  if (window.dataLayer) {
    window.dataLayer.push({
      cleanup() {
        clearTimeout(trackTimeout);
        trackEvent(params);
      },
    });
  }
}

function pageView(params: EventParams) {
  params.event_type = 'pv';

  trackEvent(params);
}

type Analytics = {
  user: any;
  provider: any;
  global: any;
  page: any;
  payments: (
    eventName: string,
    type: string,
    id?: string | null,
    touchPointButton?: string,
    paymentType?: string
  ) => void;
  mockPlatform: (platform: string) => void;
  paymentsPageView: (arg0: any, arg1: any, arg2: any) => void;
  clearPlatformMock: () => void;
  trackEvent: (eventName: string, params?: any, cb?: () => void) => void;
};

const analytics: Analytics = {
  mockPlatform: (platform) => {
    platformType = platform;
  },

  clearPlatformMock: () => {
    platformType = initPlatformType();
  },

  page: {
    view: (label: string, params = {}) =>
      pageView({
        trackPage: label,
        touch_point_page: label,
        ...params,
      }),

    userRequest: (params: EventParams) => {
      pageView({
        ...params,
        trackPage: 'request_overview',
        touch_point_page: 'request_overview',
      });
    },

    providerRequest: (requestId: number) => {
      pageView({
        trackPage: 'request_overview',
        touch_point_page: 'request_overview',
        request_id: requestId,
      });
    },

    providerSettingsOrderHistory: () => {
      pageView({
        trackPage: 'settings_transactions',
        touch_point_page: 'settings_transactions',
      });
    },
  },

  global: {
    loginClick(touchPointButton = 'login') {
      return trackEvent({
        trackEvent: ['login'],
        touch_point_button: touchPointButton,
      });
    },

    logoutClick(callback: Callback, touchPoint: string, confirmationChoice: boolean) {
      const payload: EventParams = {
        trackLinkEvent: ['logout'],
        linkCallBack: callback,
        touch_point_button: touchPoint,
      };

      if (confirmationChoice === true || confirmationChoice === false) {
        payload.confirmed = confirmationChoice;
      }

      return trackEvent(payload);
    },

    registerProviderClick(linkCallBack: Callback, touchPointButton: string) {
      return trackEvent({
        trackLinkEvent: ['register_as_pro'],
        touch_point_button: touchPointButton,
        linkCallBack,
      });
    },

    loginSubmit(callback: Callback, viaFacebook: boolean) {
      if (callback) {
        return trackEvent({
          trackLinkEvent: ['login_submit'],
          linkCallBack: callback,
          login_method: viaFacebook ? 'fb' : 'email',
        });
      }

      return trackEvent({
        trackEvent: ['login_submit'],
        login_method: viaFacebook ? 'fb' : 'email',
      });
    },

    loginSuccess(userId: number, viaFacebook: boolean, callback: Callback) {
      if (callback) {
        return trackEvent({
          trackEvent: ['login_success'],
          login_method: viaFacebook ? 'fb' : 'email',
          linkCallBack: callback,
          user_id: userId,
        });
      }

      return trackEvent({
        trackEvent: ['login_success'],
        login_method: viaFacebook ? 'fb' : 'email',
        user_id: userId,
      });
    },

    loginError(viaFacebook: boolean) {
      return trackEvent({
        trackEvent: ['login_error'],
        login_method: viaFacebook ? 'fb' : 'email',
      });
    },

    profileContactProvider(touchPointButton = 'top', providerId?: string) {
      return trackEvent({
        trackEvent: ['sp_profile_user_direct_req_button_click'],
        touch_point_button: touchPointButton,
        provider_id: providerId,
      });
    },

    referralSubmit() {
      return trackEvent({
        trackEvent: ['referral_submit'],
      });
    },

    referralSuccess() {
      return trackEvent({
        trackEvent: ['referral_success'],
      });
    },

    referralError() {
      return trackEvent({
        trackEvent: ['referral_error'],
      });
    },

    externalReviewStars() {
      return trackEvent({
        trackEvent: ['external_review_click_stars'],
      });
    },

    externalReviewText() {
      return trackEvent({
        trackEvent: ['external_review_click_text'],
      });
    },

    externalReviewService() {
      return trackEvent({
        trackEvent: ['external_review_choose_service'],
      });
    },

    externalReviewContact(touchPointButton: string) {
      return trackEvent({
        trackEvent: ['external_review_choose_contact_option'],
        touch_point_button: touchPointButton,
      });
    },

    externalReviewSuccess(touchPointButton: string, callback: Callback) {
      return trackEvent({
        trackLinkEvent: ['external_review_success'],
        touch_point_button: touchPointButton,
        linkCallBack: callback,
      });
    },
  },

  user: {
    searchStart(touchPointButton: string) {
      return trackEvent({
        trackEvent: ['search_start'],
        touch_point_button: touchPointButton,
      });
    },

    searchQuery(keyword: string, suggestionsIds: string[], suggestionsNames: string[]) {
      return trackEvent({
        trackEvent: ['search_query'],
        keyword_string: keyword,
        suggestion_ids: suggestionsIds,
        suggestion_names: suggestionsNames,
      });
    },

    searchStep1(keyword: string, suggestionsIds: string[], suggestionsNames: string[]) {
      return trackEvent({
        trackEvent: ['search_step1'],
        keyword_string: keyword,
        suggestion_ids: suggestionsIds,
        suggestion_names: suggestionsNames,
      });
    },

    searchComplete(
      keyword: string,
      chosenResult: string,
      suggestionsIds: string[],
      suggestionsNames: string[],
      touchPointButton: string,
      callback: Callback
    ) {
      return trackEvent({
        trackEvent: ['search_complete'],
        touch_point_button: touchPointButton,
        keyword_string: keyword,
        chosen_result: chosenResult,
        suggestion_ids: suggestionsIds,
        suggestion_names: suggestionsNames,
        linkCallBack: callback,
      });
    },

    serviceRequestStart(isLocationIdentified: boolean, touchPointButton: string, wizardFlowId: string) {
      const payload: EventParams = {
        trackEvent: ['request_start'],
        question_nb: 0,
        is_location_identified: isLocationIdentified,
        wizard_flow_id: wizardFlowId,
      };

      if (touchPointButton) {
        payload.touch_point_button = touchPointButton;
      }

      return trackEvent(payload);
    },

    serviceRequestWizardStart() {
      return trackEvent({
        trackEvent: ['request_wizard_start'],
        question_nb: 1,
      });
    },

    serviceRequestSeeProviders(callback: Callback) {
      return trackEvent({
        trackLinkEvent: ['see_providers'],
        linkCallBack: callback,
      });
    },

    serviceRequestSelectSchedule(question: Question) {
      return trackEvent({
        trackEvent: ['select_service_schedule'],
        question_id: question.id,
        question_type: question.type,
        question_format: question.format,
        question_nb: question.nb,
        total_questions: question.total,
        direction: question.direction,
        wizard_flow_id: question.wizard_flow_id,
      });
    },

    serviceRequestSelectInfo(question: Question) {
      return trackEvent({
        trackEvent: ['select_service_additional_info'],
        question_id: question.id,
        question_type: question.type,
        question_format: question.format,
        question_nb: question.nb,
        total_questions: question.total,
        direction: question.direction,
        wizard_flow_id: question.wizard_flow_id,
      });
    },

    serviceRequestSubmitInfo(infoAdded: boolean, wizardFlowId: string) {
      return trackEvent({
        trackEvent: ['complete_service_additional_info'],
        info_added: infoAdded,
        wizard_flow_id: wizardFlowId,
      });
    },

    serviceRequestSelectLocation(question: Question) {
      return trackEvent({
        trackEvent: ['select_location_step'],
        question_id: question.id,
        question_type: question.type,
        question_format: question.format,
        question_nb: question.nb,
        total_questions: question.total,
        direction: question.direction,
      });
    },

    serviceRequestSelectLocationInput: () =>
      trackEvent({
        trackEvent: ['input_location'],
      }),

    serviceRequestCompleteSchedule(selectedSchedule: any, wizardFlowId: string) {
      return trackEvent({
        trackEvent: ['complete_service_schedule'],
        schedule_time_span: selectedSchedule,
        wizard_flow_id: wizardFlowId,
      });
    },

    serviceRequestCompleteLocation(address: string | undefined, id: string | undefined) {
      return trackEvent({
        trackEvent: ['complete_location_step'],
        address,
        city_name: address || '',
        city: id || '',
      });
    },

    serviceRequestSelectEmail(question: any) {
      return trackEvent({
        trackEvent: ['select_service_email'],
        question_id: question.id,
        question_type: question.type,
        question_format: question.format,
        question_nb: question.nb,
        total_questions: question.total,
        direction: question.direction,
        wizard_flow_id: question.wizard_flow_id,
      });
    },

    serviceRequestEmailValid() {
      return trackEvent({
        trackEvent: ['email_valid'],
      });
    },

    serviceRequestPhoneValid() {
      return trackEvent({
        trackEvent: ['phone_valid'],
      });
    },

    serviceRequestSelectLogin(question: Question) {
      return trackEvent({
        trackEvent: ['login'],
        question_id: question.id,
        question_type: question.type,
        question_format: question.format,
        question_nb: question.nb,
        total_questions: question.total,
        direction: question.direction,
      });
    },

    serviceRequestSelectRegister() {
      return trackEvent({
        trackEvent: ['user_registration_start'],
      });
    },

    serviceRequestQuestion(question: Question) {
      return trackEvent({
        trackEvent: [`question_${question.nb}`],
        question_id: question.id, // @fixme Check if overridden
        question_type: question.type,
        question_format: question.format,
        question_nb: question.nb,
        total_questions: question.total,
        direction: question.direction,
        wizard_flow_id: question.wizard_flow_id,
      });
    },

    serviceRequestAnswerTick(wizardFlowId: string) {
      return trackEvent({
        trackEvent: ['answer_tick'],
        wizard_flow_id: wizardFlowId,
      });
    },

    serviceRequestAnswerUntick(wizardFlowId: string) {
      return trackEvent({
        trackEvent: ['answer_untick'],
        wizard_flow_id: wizardFlowId,
      });
    },

    serviceRequestPasswordReset() {
      return trackEvent({
        trackEvent: ['password_reminder'],
      });
    },

    serviceRequestClose(requestModalCloser: string) {
      trackEvent({
        trackEvent: ['request_closed'],
        touch_point_button: requestModalCloser,
      });

      // Cleanup the data layer after
      trackEvent({
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        cleanup() {},
      });
    },

    serviceRequestSend(callback: Callback) {
      return trackEvent({
        trackLinkEvent: ['send_request'],
        linkCallBack: callback,
      });
    },

    serviceRequestSuccess(
      requestId: string,
      userId: string,
      chosenResult = '',
      callback: () => any,
      wizardFlowId: string,
      touchPointButton?: string
    ) {
      return trackEvent({
        trackLinkEvent: ['request_success'],
        request_id: requestId,
        user_id: userId,
        chosen_result: chosenResult,
        linkCallBack: callback,
        wizard_flow_id: wizardFlowId,
        touch_point_button: touchPointButton,
      });
    },

    serviceRequestCancelPrompt(op: EventParams) {
      const params = op;
      params.trackEvent = ['prompt_request_view'];

      return trackEvent(params);
    },

    serviceRequestCancelPromptRegister() {
      return trackEvent({
        trackEvent: ['prompt_registration_view'],
      });
    },

    serviceRequestCancelContinue(op: EventParams) {
      const params = op;
      params.trackEvent = ['prompt_request_continue'];

      return trackEvent(params);
    },

    serviceRequestCancelContinueRegister() {
      return trackEvent({
        trackEvent: ['prompt_registration_continue'],
      });
    },

    fileUploadSuccess: (extension: string, size: number) =>
      trackEvent({
        trackEvent: [`wizard_file_upload_success_${extension}_${size}Kb`],
      }),

    fileUploadFail: (extension: string, size: number) =>
      trackEvent({
        trackEvent: [`wizard_file_upload_failed_${extension}_${size}Kb`],
      }),

    makeNewRequest() {
      return cleanupTrackEvent({
        trackEvent: ['make_new_request'],
      });
    },

    makeNewRequestAsync(params: EventParams, callback: Callback) {
      params.trackLinkEvent = ['make_new_request'];
      params.linkCallBack = callback;

      return trackEvent(params);
    },

    hideRequestDetails(params: EventParams) {
      params.trackEvent = ['hide_request_details'];

      return cleanupTrackEvent(params);
    },

    showRequestDetails(params: EventParams) {
      params.trackEvent = ['show_request_details'];

      return cleanupTrackEvent(params);
    },

    cancelRequest(params: EventParams) {
      params.trackEvent = ['cancel_request'];

      return cleanupTrackEvent(params);
    },

    rateProvider(params: EventParams) {
      params.trackEvent = ['rate_provider'];

      return trackEvent(params);
    },

    userRegistrationSubmit(callback: Callback) {
      if (callback) {
        return trackEvent({
          trackLinkEvent: ['user_registration_submit'],
          is_professional: false,
          linkCallBack: callback,
        });
      }

      return trackEvent({
        trackEvent: ['user_registration_submit'],
      });
    },

    registrationSubmit(params: EventParams) {
      params.trackEvent = ['registration_submit'];

      return trackEvent(params);
    },

    registrationSuccess(callback: Callback) {
      return trackEvent({
        trackEvent: ['registration_success'],
        linkCallBack: callback,
      });
    },

    registrationError(params: EventParams) {
      params.trackEvent = ['registration_error'];

      return trackEvent(params);
    },

    chatImageSent(requestId: string, providerId: string, userId: string) {
      return trackEvent({
        trackEvent: ['chat_user_tap_add_pic'],
        user_id: userId,
        provider_id: providerId,
      });
    },

    chatLocationShare(requestId: string, providerId: string, userId: string) {
      return trackEvent({
        trackEvent: ['chat_user_tap_share_location'],
        user_id: userId,
        provider_id: providerId,
      });
    },

    chatShareContactsOpen(requestId: string, providerId: string, userId: string) {
      return trackEvent({
        trackEvent: ['chat_user_tap_share_contact'],
        user_id: userId,
        provider_id: providerId,
      });
    },

    chatShareLinkContact(requestId: string, providerId: string, userId: string) {
      return trackEvent({
        trackEvent: ['chat_user_tap_send_website'],
        user_id: userId,
        provider_id: providerId,
      });
    },

    chatSharePhoneContact(requestId: string, providerId: string, userId: string) {
      return trackEvent({
        trackEvent: ['chat_user_tap_send_phone'],
        user_id: userId,
        provider_id: providerId,
      });
    },

    prefillLocationSuccess: (city: City) =>
      trackEvent({
        trackEvent: ['prefill_location_success'],
        is_location_identified: true,
        city: city && city.id ? city.id : '',
        city_name: city && city.name ? city.name : '',
      }),

    prefillLocationFail: () =>
      trackEvent({
        trackEvent: ['prefill_location_fail'],
      }),

    prefillCompleteLocation: () =>
      trackEvent({
        trackEvent: ['complete_location'],
      }),

    externalReviewError() {
      return trackEvent({
        trackEvent: ['external_review_error'],
      });
    },

    openFeedbackModal(touchPointButton: string, requestId: string, conversationId: string) {
      return trackEvent({
        trackEvent: ['rate_sp_click'],
        touch_point_button: touchPointButton,
        request_id: requestId,
        quote_id: conversationId,
      });
    },

    closeFeedbackModal(touchPointButton: string, requestId: string, userId: string) {
      return trackEvent({
        trackEvent: ['close_rate_modal'],
        touch_point_button: touchPointButton,
        request_id: requestId,
        user_id: userId,
      });
    },

    selectStars(touchPointButton: string, value: number, requestId: string, conversationId: string) {
      return trackEvent({
        trackEvent: ['select_stars'],
        touch_point_button: touchPointButton,
        request_id: requestId,
        quote_id: conversationId,
        value,
      });
    },

    switchConversation(
      touch_point_button: string,
      params: { requestId: string; conversationId: string },
      cb?: () => void
    ): void {
      trackEvent({
        trackEvent: ['chat_user_switch_conversation'],
        touch_point_button,
        request_id: params.requestId,
        quote_id: params.conversationId,
        linkCallBack: cb,
      });
    },

    openChatProfilePreview(touch_point_button: string, requestId: string, conversationId: string): void {
      trackEvent({
        trackEvent: ['chat_user_show_sp_profile'],
        touch_point_button,
        request_id: requestId,
        quote_id: conversationId,
      });
    },

    galleryOpen(requestId: string, conversationId: string): void {
      trackEvent({
        trackEvent: ['chat_user_show_sp_gallery'],
        request_id: requestId,
        quote_id: conversationId,
      });
    },

    galleryImageSlide(requestId: string, conversationId: string): void {
      trackEvent({
        trackEvent: ['chat_user_scroll_gallery'],
        request_id: requestId,
        quote_id: conversationId,
      });
    },

    chatUserSendMessage(
      events: string,
      params: {
        requestId: string;
        conversationId: string;
        providerId: string;
      }
    ): void {
      trackEvent({
        trackEvent: Array.isArray(events) ? events : [events],
        request_id: params.requestId,
        quote_id: params.conversationId,
        provider_id: params.providerId,
      });
    },

    finalizeProfileModal: {
      modalClosed() {
        return trackEvent({
          trackEvent: ['complete_registration_popup_cancel'],
        });
      },
      submit(viaFacebook: boolean, linkCallBack: Callback) {
        return trackEvent({
          trackEvent: ['complete_registration_popup_submit'],
          password: !viaFacebook,
          linkCallBack,
        });
      },
    },

    wizardSignin(touchPointButton: string, wizardFlowId: string) {
      return trackEvent({
        trackEvent: ['select_service_email_details'],
        touch_point_button: touchPointButton,
        wizard_flow_id: wizardFlowId,
      });
    },

    sendEmailVerification() {
      return trackEvent({
        trackEvent: ['verify_email_resend'],
      });
    },

    showQuoteReminder(requestId: string, touchPointPage: string) {
      return trackEvent({
        trackEvent: ['request_review_reminder_show'],
        request_id: requestId,
        touch_point_page: touchPointPage,
      });
    },

    showRatingModalClose(params: { requestId: string; conversationId: string; providerId: string }) {
      trackEvent({
        trackEvent: ['close_rate_modal'],
        request_id: params.requestId,
        quote_id: params.conversationId,
        provider_id: params.providerId,
      });
    },

    hideRatingModalClose(closingConfirmed: boolean) {
      trackEvent({
        trackEvent: ['rate_provider_quit'],
        touch_point_button: closingConfirmed ? 'quit' : 'review',
        // request_id, quote_id, provider_id should be taken from previous events
      });
    },

    deleteInternalReviewByToken(params: { requestId: string; conversationId: string }) {
      trackEvent({
        trackEvent: ['chat_user_delete_sms_review'],
        request_id: params.requestId,
        quote_id: params.conversationId,
      });
    },

    userStartsExternalReview() {
      trackEvent({
        trackEvent: ['user_add_sms_review_start'],
      });
    },

    openPromoVideo() {
      trackEvent({
        trackEvent: ['video_banner_click'],
      });
    },

    closePromoVideo() {
      trackEvent({
        trackEvent: ['video_banner_dismiss'],
      });
    },

    rateAdditionalServices(requestId: string) {
      trackEvent({
        trackEvent: ['rate_provider_additional_services'],
        request_id: requestId,
      });
    },
  },

  provider: {
    askForReview() {
      return trackEvent({
        trackEvent: ['tap_request_review'],
      });
    },

    askForMultipleReview(requestId: string) {
      return trackEvent({
        trackEvent: ['tap_for_multiple_review'],
        request_id: requestId,
      });
    },

    archiveRequest(requestId: string) {
      return trackEvent({
        trackEvent: ['sp_click_req_archive'],
        request_id: requestId,
      });
    },

    initiateContact(requestId: string, value: string, l2Slug: string, l3Slug: string, l4Slug: string) {
      return trackEvent({
        trackEvent: ['chat_sp_tap_conversation_open'],
        request_id: requestId,
        l2_slug: l2Slug,
        l3_slug: l3Slug,
        l4_slug: l4Slug,
        value,
      });
    },

    conversationStart(requestId: string) {
      return trackEvent({
        trackEvent: ['chat_sp_tap_conversation_start'],
        request_id: requestId,
      });
    },

    dismissRequest(requestId: string, cb: Callback) {
      return trackEvent({
        trackEvent: ['chat_sp_tap_conversation_decline'],
        request_id: requestId,
        linkCallBack: cb,
      });
    },

    chatImageSent(requestId: string, providerId: string) {
      return trackEvent({
        trackEvent: ['chat_sp_tap_add_pic'],
        request_id: requestId,
        user_id: providerId,
      });
    },

    chatShareContactsOpen(requestId: string, providerId: string) {
      return trackEvent({
        trackEvent: ['chat_sp_tap_share_contact'],
        request_id: requestId,
        user_id: providerId,
      });
    },

    chatShareLinkContact(requestId: string, providerId: string) {
      return trackEvent({
        trackEvent: ['chat_sp_tap_send_website'],
        request_id: requestId,
        user_id: providerId,
      });
    },

    chatSharePhoneContact(requestId: string, providerId: string) {
      return trackEvent({
        trackEvent: ['chat_sp_tap_send_phone'],
        request_id: requestId,
        user_id: providerId,
      });
    },

    chatLocationShare(requestId: string, providerId: string) {
      return trackEvent({
        trackEvent: ['chat_sp_tap_share_location'],
        request_id: requestId,
        user_id: providerId,
      });
    },

    showUserContacts(quoteId: string, tpp?: string, tpb?: string) {
      return trackEvent({
        trackEvent: ['chat_sp_show_user_phone'],
        quote_id: quoteId,
        touch_point_page: tpp,
        touch_point_button: tpb,
      });
    },

    appPromoClick(touchPointButton = 'outdated') {
      return trackEvent({
        trackEvent: ['app_promo_click'],
        touch_point_button: touchPointButton,
      });
    },

    openSettingsLocation() {
      return trackEvent({
        trackEvent: ['settings_location'],
      });
    },

    saveSettingsLocation() {
      return trackEvent({
        trackEvent: ['settings_location_save'],
      });
    },

    closeSettingsLocationModal() {
      return trackEvent({
        trackEvent: ['settings_location_close'],
      });
    },

    orderHistoryItemClick() {
      return trackEvent({
        trackEvent: ['settings_transactions_transaction_click'],
      });
    },

    premiumOptionView() {
      return trackEvent({
        trackEvent: ['settings_paid_options'],
      });
    },

    showQuoteReminder(requestId: string, conversationId: string) {
      return trackEvent({
        trackEvent: ['quotes_review_reminder_show'],
        request_id: requestId,
        quote_id: conversationId,
      });
    },

    collectReviews(actionType: string, params: { touchPointButton?: string; touchPointPage?: string }) {
      return trackEvent({
        trackEvent: [`tap_request_review_${actionType}`],
        touch_point_button: params.touchPointButton,
        touch_point_page: params.touchPointPage,
      });
    },

    collectReviewsModal() {
      return trackEvent({
        trackEvent: ['receive_reviews_popup_show'],
      });
    },

    clickMoreReviews({
      touchPointPage = 'available_requests',
      touchPointButton,
    }: {
      touchPointButton?: string;
      touchPointPage?: string;
    }) {
      trackEvent({
        trackEvent: ['sp_click_more_reviews'],
        touch_point_page: touchPointPage,
        touch_point_button: touchPointButton,
      });
    },

    tapMoreRequests({ touchPointPage = 'available_requests' }) {
      trackEvent({
        trackEvent: ['sp_tap_more_requests'],
        touch_point_page: touchPointPage,
      });
    },

    showRequestToUnlock({ touchPointButton, touchPointPage }: { touchPointButton?: string; touchPointPage?: string }) {
      trackEvent({
        trackEvent: ['sp_show_request_to_unlock'],
        touch_point_page: touchPointPage,
        touch_point_button: touchPointButton,
      });
    },

    adminMessage(eventName: string, messageId: string, params: {}) {
      trackEvent({
        trackEvent: [eventName],
        touch_point_page: messageId,
        ...params,
      });
    },

    pointsNumberChange(value: string, touchPointButton?: string) {
      trackEvent({
        trackEvent: ['premium_change_quantity_click_points_package'],
        value,
        touch_point_button: touchPointButton,
      });
    },

    starsBalance(url: string) {
      trackEvent({
        trackEvent: ['stars_balance_sp_click'],
        touch_point_page: getPageLabelByUrl(url),
      });
    },
  },

  payments: (eventName: string, type: string, id = '', touchPointButton = '', value?: string) => {
    const params: EventParams = {
      trackEvent: [eventName],
      rel_entity_type: type,
    };
    if (id) {
      params.rel_entity_id = id;
    }
    if (touchPointButton) {
      params.touch_point_button = touchPointButton;
    }

    if (value) {
      params.value = value;
    }

    trackEvent(params);
  },

  trackEvent: (eventName, params, callback) => {
    const eventParams = {
      [callback ? 'trackLinkEvent' : 'trackEvent']: [eventName],
      ...params,
    };

    if (callback) {
      eventParams.linkCallBack = callback;
    }

    return trackEvent(eventParams);
  },

  paymentsPageView: (pageLabel, touchPointButton, value) =>
    pageView({
      trackPage: pageLabel,
      touch_point_button: touchPointButton,
      value,
    }),
};

export default analytics;

/* eslint max-len:0 */
