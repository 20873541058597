import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { from as createLinkFrom } from 'apollo-link';
import { NormalizedCacheObject } from 'apollo-cache-inmemory/lib/types';

import { authorizationMiddleware } from './middleware/authorizationMiddleware';
import { loginTokenMiddleware } from './middleware/loginToken';
import { errorMiddleware } from './middleware/errorMiddleware';

import { isWindow } from 'common/helpers/helpers';
import config from 'config/config';
import auth from 'utils/auth/auth';

import { messageFragmentMatcher } from 'Components/RequestConversations/RequestConversationHelpers';
import { apiHeaders } from '../api/headers';

declare global {
  interface Window {
    __APOLLO_STATE__: NormalizedCacheObject;
  }
}

function getCache() {
  const cacheManager = new InMemoryCache({
    fragmentMatcher: messageFragmentMatcher,
  });

  return isWindow ? cacheManager.restore(window.__APOLLO_STATE__) : cacheManager;
}

const retryOnStatus = async (
  failureStatus: number,
  fetchCall: () => Promise<Response>,
  beforeRetryCall: () => Promise<unknown>
): Promise<Response> => {
  const response = await fetchCall();
  if (response.status !== failureStatus) {
    return response;
  }

  await beforeRetryCall();
  return await fetchCall();
};

const customFetch = async (input: RequestInfo, init?: RequestInit) => {
  const body = init?.body ? JSON.parse(String(init.body)) : undefined;
  const url = typeof input === 'string' && body.operationName ? `${input}?name=${body.operationName}` : input;

  return await retryOnStatus(
    401,
    () => fetch(url, init),
    () => auth.refresh()
  );
};

function getLink() {
  const httpLink = new HttpLink({
    uri: `${config.API_URL}/graphql`,
    fetch: customFetch,
    credentials: 'include',
    headers: apiHeaders,
  });

  return createLinkFrom([loginTokenMiddleware, authorizationMiddleware, errorMiddleware, httpLink]);
}

export const client = new ApolloClient({
  link: getLink(),
  cache: getCache(),
});

export const graphQLErrorHandler = (error: any, componentName: string) => {
  // TODO: Add proper error handler here
  return { error, componentName };
};
