import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { withNoSSR } from 'Components/NoSSR';
import { isRoute, isMobileWebView } from 'common/helpers/helpers';
import config from 'config/config';

import { isCookiesAccepted, setCookiesAccepted } from './CookieBanner.helpers';
import './CookieBanner.scss';

const stickyHeaderRoutes = ['front::index', 'front::categories.type', 'categories_seo'];

const CookieBanner: FC = () => {
  const [isVisible, setIsVisible] = useState(!isCookiesAccepted() && !isMobileWebView());
  const location = useLocation();

  if (!isVisible) return null;

  const bannerCN = classnames('cookieBanner', {
    'cookieBanner_withStickyHeader': stickyHeaderRoutes.some((routeKey) => isRoute(location.pathname, routeKey)),
  });

  return (
    <div className={bannerCN}>
      <div className="cookieBanner__body">
        <Trans id="cookie_banner_message">
          Korzystając z naszych usług, przyjmujesz do wiadomości, że używamy plików cookie i podobnych technologii do
          ulepszania i dostosowania treści, analizy ruchu, dostarczania reklam oraz ochrony przed spamem, złośliwym
          oprogramowaniem i nieuprawnionym dostępem.{' '}
          <a href={config.HELPDESK_COOKIE_URL} target="_blank" rel="noopener noreferrer">
            Dowiedz się więcej
          </a>
          .
        </Trans>
      </div>
      <button
        type="button"
        onClick={() => {
          setIsVisible(false);
          setCookiesAccepted();
        }}
        className="cookieBanner__closeButton"
      >
        <span className="cookieBanner__closeIcon" aria-label="Close" />
      </button>
    </div>
  );
};

export default withNoSSR(CookieBanner);
