import config from 'config/config';

const oneTrustDomainScript = '018e50a3-3b15-7429-aa44-628b5e30548d';

export const OneTrustScripts = () => {
  const domainScript = config.isDevEnv ? `${oneTrustDomainScript}-test` : oneTrustDomainScript;

  // Load OneTrust scripts
  // const oneTrustAutoBlockScriptTag = document.createElement('script');
  // oneTrustAutoBlockScriptTag.type = 'text/javascript';
  // oneTrustAutoBlockScriptTag.src = `https://cdn.cookielaw.org/consent/${domainScript}/OtAutoBlock.js`;
  // document.head.appendChild(oneTrustAutoBlockScriptTag);

  // const oneTrustScriptTag = document.createElement('script');
  // oneTrustScriptTag.type = 'text/javascript';
  // oneTrustScriptTag.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
  // oneTrustScriptTag.charset = 'UTF-8';
  // oneTrustScriptTag.setAttribute('data-language', 'pl');
  // oneTrustScriptTag.setAttribute('data-domain-script', domainScript);
  // document.head.appendChild(oneTrustScriptTag);

  // return () => {
  //   document.head.removeChild(oneTrustAutoBlockScriptTag);
  //   document.head.removeChild(oneTrustScriptTag);
  // };
};
